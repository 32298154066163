.contact-us {
    padding: max(70px, min(14.8vw, 150px)) 0 max(50px, min(5.9vw, 60px));
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    margin-top: 100px;
    @include table {
        margin-top: 80px;
    }
    &__block {
        display: flex;
        justify-content: space-between;
        margin-top: 62px;
        @include table {
            flex-direction: column;
        }
    }
    &__box {
        display: flex;
        align-items: center;
        @include table {
            &:not(:last-child) {
                margin-bottom: max(38px, min(6.6vw, 50px));
            }
        }
    }
    &__icon {
        width: 48px;
        height: 48px;
        object-fit: contain;
    }
    &__group {
        display: flex;
        flex-direction: column;
        @include table--min {
            padding-left: max(20px, min(2.5vw, 30px));
        }
        @include table {
            padding-left: max(20px, min(4vw, 30px));
        }
    }
    &__text {
        font-weight: 700;
        color: var(--text-primary);
        line-height: 1.1;
        @include table--min {
            font-size: max(18px, min(2vw, 24px));
        }
        @include table {
            font-size: max(16px, min(4.5vw, 24px));
        }
    }
    h1 {
        margin-bottom: 40px;
    }
}
.contact-form {
    padding-top: 50px;
    .h1 {
        color: var(--text-main);
        margin-bottom: 20px;
    }
    &__cont {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
    }
    &__row {
        &:not(.two), &:not(.three) {
            display: flex;
        }
        &:not(:last-child) {
            margin-bottom: 26px;
        }
        &.two, &.three {
            display: grid;
            grid-gap: 26px;
        }
        &.two {
            grid-template-columns: repeat(2, 1fr);
            @include table {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        &.three {
            grid-template-columns: repeat(3, 1fr);
            @media screen and (max-width: 1024px) and (min-width: 481px) {
                grid-template-columns: repeat(2, 1fr);
                .form__box:last-child {
                    grid-column-start: 1;
                    grid-column-end: 3;
                }
            }
            @include phone-small {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        .form__box:not(:last-child) {
            margin-bottom: 0;
        }
    }
    .btn {
        width: 100%;
        max-width: 344px;
        margin: 50px auto 0;
    }
    .checked {
        max-width: 320px;
    }
}
