.admin-vouchers {
    .wrapper-large {
        padding-top: 0;
    }
    .btn {
        max-width: 464px;
        width: 100%;
        margin: 0 auto 50px;
    }
    &__tabs {
        display: flex;
        max-width: 1146px;
        width: 100%;
        @include table--min {
            margin-bottom: 12px;
        }
        @include table {
            margin-top: 16px;
        }
    }
    &__tabs-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 62px;
        width: 50%;
        color: var(--color-primary);
        font-size: max(13px, min(3.4vw, 16px));
        text-align: center;
        padding: 0 8px;
        text-transform: uppercase;
        cursor: pointer;
        @include ti-1;
        @include hover {
            &:hover {
                color: var(--color-secondary);
            }
        }
        &:not(:last-child) {
            margin-right: 20px;
        }
        &.active {
            color: var(--text-primary);
            background-color: var(--color-secondary);
        }
    }
    .admin-influencers__table {
        flex-direction: column;
    }
}
.admin-influencers-pending {
  .wrapper-large {
    padding-top: 0;
  }
  .btn {
    max-width: 464px;
    width: 100%;
    margin: 0 auto 50px;
  }
  &__tabs {
    display: flex;
    max-width: 1146px;
    width: 100%;
    @include table--min {
      margin-bottom: 12px;
    }
    @include table {
      margin-top: 16px;
    }
  }
  .table__td, .table__th {
    @include table--min {
      &:not(:last-child) {
        margin-right: 20px;
      }
      &:nth-child(1) {
        max-width: 500px;
      }
      &:nth-child(2) {
        max-width: 500px;
      }
      &:nth-child(3) {
        max-width: 200px;
        justify-content: space-evenly;
      }
    }
  }
  &__tabs-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 62px;
    width: 50%;
    color: var(--color-primary);
    font-size: max(13px, min(3.4vw, 16px));
    text-align: center;
    padding: 0 8px;
    text-transform: uppercase;
    cursor: pointer;
    @include ti-1;
    @include hover {
      &:hover {
        color: var(--color-secondary);
      }
    }
    &:not(:last-child) {
      margin-right: 20px;
    }
    &.active {
      color: var(--text-primary);
      background-color: var(--color-secondary);
    }
  }
  .admin-influencers__table {
    flex-direction: column;
  }
}
.table-vouchers {
    .table__td, .table__th {
        @include table--min {
            &:not(:last-child) {
                margin-right: 20px;
            }
            &:nth-child(1) {
                max-width: 100px;
            }
          &:nth-child(2) {
            max-width: 200px;
          }
            &:nth-child(3) {
                max-width: 344px;
            }
            &:nth-child(4) {
                max-width: 198px;
            }
            &:nth-child(5) {
              min-width: 200px;
                 width: 200px;
            }
            &:nth-child(6) {
                 max-width: 198px;
            }
        }
    }
    .table__tr {
        &:nth-child(even) .table__td {
            @include table--min {
                background-color: var(--table-td);
            }
        }
        &.expired {
            .table__text, .table__number {
                color: #B4B4B4;
            }
            .table__icon {
                opacity: .5;
            }
        }
    }
    .table__td {
        justify-content: center;
        text-align: center;
        @include table {
            &:nth-child(5) {
                top: 100%;
                transform: translateY(-100%);
            }
            &:nth-child(1) {
                padding-right: 20px;
            }
        }
    }
    .table__number {
        font-size: 20px;
    }
    .table__text {
        font-size: 16px;
        font-weight: 500;
    }
}
.admin-add-voucher {
    position: relative;
    &__wrapper {
        max-width: 1196px;
        width: 100%;
        margin: 0 auto;
    }
    &__box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:nth-child(1) {
            margin-bottom: 20px;
            @include phone-large {
                flex-direction: column-reverse;
                align-items: flex-start;
            }
        }
        &:nth-child(2) {
            margin-bottom: 48px;
        }
    }
    &__captain {
        max-width: 1076px;
        margin-left: auto;
    }
    &__step {
        font-family: var(--font-secondary);
        font-size: 24px;
        font-weight: 700;
        white-space: nowrap;
        line-height: 1;
        @include phone-large {
            position: absolute;
            top: 38px;
            right: 29px;
            font-size: 18px;
        }
    }
    &__group {
        display: flex;
        align-items: center;
        text-align: right;
        @include phone-large {
            margin-bottom: 16px;
        }
        img {
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin-right: 10px;
        }
    }
    &__text {
        font-family: var(--font-secondary);
        font-weight: 500;
    }
    .wrapper-large {
        padding-top: 86px;
        padding-bottom: 52px;
        background-color: var(--bg-primary);
        @include phone-table {
            padding-top: 62px;
        }
    }
    .h2 {
        font-size: max(20px, min(3vw, 32px));
        @include phone-large--min {
            padding-right: 16px;
        }
    }
    .btn {
        max-width: 344px;
        width: 100%;
        margin: 50px auto 0;
    }
    .campaigns__box {
        &.active {
            pointer-events: none;
        }
        &:not(.active) {
            filter: grayscale(1);
            .campaigns__title {
                color: var(--text-secondary);
                opacity: .5;
            }
        }
    }
    .form__upload {
        max-width: 100%;
        .form-upload {
            @include phone-small {
                padding: 0 54px 0 20px;
                &__info {
                    padding-left: 20px;
                }
                &__icon {
                    right: 16px;
                }
            }
        }
    }
}
.voucher-wrap {
    max-width: 954px;
    width: 100%;
    margin: 0 auto;
}
.voucher {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
        margin-bottom: 50px;
    }
    &__title {
        margin-bottom: 10px;
    }
    &__group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        @include phone-small {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }
    &__image {
        width: calc(100% - 190px);
        max-height: 200px;
        object-fit: contain;
        object-position: left;
        @include phone-small {
            width: 100%;
        }
    }
    .form__input {
        padding: 30px;
        font-weight: 500;
    }
    .modal-reg__col:nth-child(1) {
        max-width: 582px;
    }
}

.voucher-distributed {
  display: flex;
  gap: 5px;

  &__image {
    height: 34px;
    width: 34px;

    object-fit: cover;
    border-radius: 50%;

    cursor: pointer;
    transition: all .1s ease-in;
  }
}
