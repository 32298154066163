.admin-influencers {
    .wrapper-large {
        padding-top: 12px;
        padding-bottom: 42px;
        background-color: var(--bg-primary);
    }
    &__table {
        display: flex;
        max-width: 1196px;
        width: 100%;
        margin: 0 auto 30px;
        padding-bottom: 20px;

      .empty-list {
        margin: 100px 0;
        width: 100%;
        text-align: center;
        font-size: 18px;
      }
    }
    .table {
        width: 100%;
        .tooltip {
            margin-left: 20px;
            transform: translateY(0px);
            @include table--min {
                display: none;
            }
        }
        &-head {
            @include table {
                display: none;
            }
        }
        &__th:last-child {
          max-width: 40px;
          padding: 0;
            background-color: transparent;
        }
        &__td, &__th {
            &:nth-child(7) {
                padding: 0;
                margin-left: auto;
                background-color: transparent !important;
            }
        }
        &__td {
            @include table {
                &:nth-child(1) {
                    padding-right: 70px;
                }
                &:nth-child(5) {
                    position: absolute;
                    top: 0;
                    right: 30px;
                }
            }
            &:hover {
                .tooltip {
                    opacity: 1;
                    transform: translateY(-4px);
                }
            }
        }
    }
}
.administration-hero {
    padding-top: max(70px, min(14.8vw, 100px));
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    &__title-box {
        display: flex;
        margin-bottom: 50px;
        @include phone-large--min {
            align-items: center;
            justify-content: space-between;
        }
        @include phone-large {
            flex-direction: column;
        }
    }
  &__search-box {
    width: 80%;
    height: 40px;
    margin: 0 auto 40px;

    position: relative;
    display: grid;
    place-items: center;
  }
  &__search-input {
    width: 100%;
    height: 100%;
    padding: 0 20px;

    font-size: 16px;

    outline: none;
    border: none;
  }
  &__clear-search {
    position: absolute;
    top: 0;
    right: 0;

    fill: var(--text-secondary);
    transform: scale(0.4);
    background-color: white;

    cursor: pointer;

    path {
      transition: all .1s ease-in;
    }

    &:hover {
      path {
        filter: brightness(0.8);
      }
    }
  }
    &__group {
        display: flex;
        @include phone-large--min {
            flex-direction: column;
            margin-left: 24px;
        }
        @include phone-large {
            margin-top: 20px;
            align-items: flex-end;
        }
    }
    &__text {
        font-size: max(18px, min(1.6vw, 20px));
        color: var(--text-secondary);
    }
    &__number {
        font-size: max(36px, min(3.8vw, 48px));
        line-height: 1;
        text-align: right;
        &.color {
            color: var(--color-secondary);
        }
        @include phone-large {
            margin-right: 8px;
            font-size: max(36px, min(12.8vw, 48px));
        }
    }
    .select {
        max-width: 240px;
        margin: 0 auto;
        select {
            height: 60px;
            background-color: var(--bg-primary);
            text-align: center;
        }
        @include phone-large--min {
            display: none;
        }
    }
    .tabs {
        &__btn {
            max-width: 240px;
            width: 100%;
            cursor: pointer;
        }
        @include phone-large {
            display: none;
        }
    }
}
.administration-soc {
    display: flex;
    &.number {
        .administration-soc__col {
          cursor: pointer;
            span {
                display: flex;
            }
          svg path {
            transition: all .1s ease-in;
          }
          &:hover {
            svg path {
              fill: #4BD5CA;
            }
          }
        }
    }
    &__col {
        display: flex;
        position: relative;
        width: 24px;
        height: 24px;
        &:not(:last-child) {
            margin-right: 15px;
        }
        svg {
            width: 100%;
            height: 100%;
        }
        span {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 10px;
            font-size: 12px;
            color: var(--color-secondary);
            font-weight: 500;
            white-space: nowrap;
            display: none;
        }
    }
}

.soc-account-list {
  width: 100%;
  height: 100%;
  padding: 40px 20px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  &__title {
    font-size: 24px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .soc-account-item {
    display: flex;
    align-items: center;
    gap: 10px;

    cursor: pointer;
    transition: all .1s ease-in;

    &:hover {
      color: #41E2BA;
    }

    & > span {
      font-size: 18px;
      cursor: pointer;
    }

    &__img {
      height: 40px;
      width: 40px;

      border-radius: 50%;
      object-fit: cover;
    }
  }
}
