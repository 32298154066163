@font-face {
    font-family: 'Inter';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/Inter-Regular.eot');
    src: url('../../fonts/Inter-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Inter-Regular.woff') format('woff'),
    url('../../fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/Inter-Medium.eot');
    src: url('../../fonts/Inter-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Inter-Medium.woff') format('woff'),
    url('../../fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/Inter-SemiBold.eot');
    src: url('../../fonts/Inter-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Inter-SemiBold.woff') format('woff'),
    url('../../fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/Inter-Bold.eot');
    src: url('../../fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Inter-Bold.woff') format('woff'),
    url('../../fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/OpenSans-Regular.eot');
    src: url('../../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/OpenSans-Regular.woff') format('woff'),
    url('../../fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/Poppins-Regular.eot');
    src: url('../../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Poppins-Regular.woff') format('woff'),
    url('../../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/Poppins-Medium.eot');
    src: url('../../fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Poppins-Medium.woff') format('woff'),
    url('../../fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/Poppins-SemiBold.eot');
    src: url('../../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Poppins-SemiBold.woff') format('woff'),
    url('../../fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('../../fonts/Poppins-Bold.eot');
    src: url('../../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/Poppins-Bold.woff') format('woff'),
    url('../../fonts/Poppins-Bold.ttf') format('truetype');
}
