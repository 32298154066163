.profile {
    padding-top: max(70px, min(14.8vw, 150px));
    overflow: hidden;
    &.black-bg:after {
        height: 358px;
    }
    h1 {
        color: var(--text-primary);
        @include phone-small {
            margin-bottom: 20px;
        }
    }
    .h1 {
        margin-bottom: 50px;
    }
    &__title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
        @include phone-small {
            flex-direction: column;
            align-items: unset;
        }
    }
    &__block {
        display: flex;
        justify-content: center;
        padding: 44px 32px 32px;
        background-color: var(--bg-primary);
        @include phone-small {
            margin: 0 -32px;
            padding-left: 29px;
            padding-right: 29px;
        }
        &:not(:last-child) {
            @include table--min {
                margin-bottom: 75px;
            }
            @include table {
                margin-bottom: 50px;
            }
        }
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 956px;
    }

  &__toggle-block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &.float-right {
      align-items: flex-end;
    }

    .toggle__title {
      font-size: max(36px, min(4.7vw, 48px));
      font-weight: 700;
    }

    .mat-slide-toggle-content {
      font-size: max(18px, min(2.5vw, 24px));
    }
  }
}
.profile-from {
    display: flex;
    @include table {
        flex-direction: column;
    }
    &.personal-form {
        .profile-from__col {
            &:nth-child(1) {
                @include table--min {
                    min-width: 462px;
                }
            }
        }
    }
    &.billing-form {
        .profile-from__col {
            &:nth-child(2) {
                @include table--min {
                    min-width: 462px;
                }
            }
        }
    }
    &.tax-number-form {
        @include table--min {
            .form__box {
                min-width: 462px;
                margin-bottom: 0 !important;
            }
            .btn-text {
                margin-left: auto;
                margin-bottom: 11px;
            }
            .profile-from__row {
                align-items: flex-end;
            }
        }
        @include table {
            .profile-from__row {
                flex-direction: column;
            }
            .form__box {
                margin-bottom: 46px !important;
            }
        }
    }
    &.no-edit {
        .form__input {
            background-color: transparent;
            padding: 0;
            font-size: max(18px, min(3.7vw, 26px));
            pointer-events: none;
            border-color: transparent;
          &.email-input {
            padding-top: 0;
          }
        }
    }
    &__row {
        display: flex;
        width: 100%;
    }
    &__col {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include table--min {
            max-width: 462px;
        }
        &:nth-child(1) {
            margin-right: 32px;
            @include table {
                margin: 0 0 26px;
            }
            .btn-text {
                align-self: flex-start;
            }
        }
        &:nth-child(2) {
            @include table--min {
                text-align: left;
                .form__input {
                    text-align: left;
                }
                .btn-text {
                    align-self: flex-end;
                }
            }
        }
    }
    .form__label {
        font-size: 20px;
        margin-bottom: 4px;
    }
  .form__input {
    &.email-input {
      height: auto;
      padding-top: 15px;
      resize: none;
    }
  }
    .form__box {
        &:not(:last-child) {
            margin-bottom: 26px;
        }
    }
    .toggle {
        text-align: left;
        max-width: 354px;
        margin-bottom: 26px;
        @include table--min {
            margin-left: auto;
            margin-top: auto;
        }
    }
}
