.hero {
    display: flex;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    overflow: hidden;
    &__col, &__img {
        display: flex;
        width: 100%;
    }
    &__col {
        flex-direction: column;
        max-width: 676px;
        z-index: 1;
        @include phone-large--min {
            min-width: 500px;
        }
    }
    &__img {
        position: relative;
        height: 100%;
        max-width: 540px;
        @include phone-large {
            display: flex;
            justify-content: center;
        }
        img {
            @include phone-large--min {
                position: absolute;
                max-width: unset;
                left: -260px;
                bottom: -60px;
            }
          @media screen and (max-height: 800px) {
            bottom: -200px;
          }
            @include phone-large {
                min-width: 460px;
            }
            object-fit: contain;
            object-position: bottom;
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        min-height: 100vh;
        padding-bottom: 60px;
        @include table--min {
            padding-top: 160px;
        }
        @include table {
            padding-top: 140px;
        }
        @include phone-large {
            flex-direction: column;
            padding-bottom: 0;
        }
    }
    .btn {
        margin-top: 52px;
        max-width: 344px;
    }
    h1 {
        font-size: max(48px, min(4.7vw, 60px));
        line-height: 1.1;
        @include phone-large {
            font-size: max(36px, min(7.9vw, 60px));
        }
    }
}
.what-sc {
    position: relative;
    z-index: 1;
    @include phone-large--min {
        margin-top: -60px;
    }
    &__wrap {
        display: flex;
        flex-direction: column;
        max-width: 1064px;
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 48px;
        }
    }
    &__video {
        display: flex;
        width: 100%;
        height: max(280px, min(50vw, 600px));
        max-width: 1196px;
        margin-bottom: 48px;
        iframe, video {
            width: 100%;
            height: 100%;
        }
        @include phone-large {
            width: calc(100% + 58px);
            margin-left: -29px;
            margin-right: -29px;
        }
    }
    &__title {
        font-size: max(24px, min(3vw, 36px));
        font-weight: 500;
        margin-bottom: 16px;
    }
    &__text {
        font-size: max(18px, min(2vw, 24px));
        color: var(--text-secondary);
    }
    .wrapper-large {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--bg-primary);
        padding-top: 100px;
        padding-bottom: 60px;
        @include phone-large {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
    .h1 {
        margin-bottom: 30px;
    }
    .btn {
        max-width: 344px;
        margin-top: 50px;
    }
}
.ambassadors {
   // $m-x: max(10px, min(2vw, 24px));

    margin: 100px 0;
    &__wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1064px;
    }
    &__grid {
        display: flex;
        width: calc(100% + max(10px, min(2vw, 24px)) * 2);
        margin: 50px 0 calc(50px - max(10px, min(2vw, 24px)) * 2);
        justify-content: center;
        flex-wrap: wrap;
    }
    &__box {
        display: flex;
        width: 100%;
        max-width: 200px;
        flex-direction: column;
        align-items: center;
        margin: 0 max(10px, min(2vw, 24px)) calc(max(10px, min(2vw, 24px)) * 2) max(10px, min(2vw, 24px));
        padding: 40px 12px;
        background-color: var(--bg-primary);
        @include phone-small {
            max-width: unset;
            padding: 24px 12px;
        }
    }
    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background-color: var(--bg-secondary);
        margin-bottom: 24px;
        img {
            width: 48px;
            height: 48px;
            object-fit: contain;
        }
    }
    &__text {
        font-family: var(--font-secondary);
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .btn {
        width: 100%;
        max-width: 466px;
    }
    .h1 {
        color: var(--text-main);
        margin-bottom: 30px;
    }
}
.banner {
    background-color: var(--bg-secondary);
    .wrapper-large {
        display: flex;
        max-width: 1374px;
        min-height: 536px;
        @include table {
            min-height: 400px;
        }
        @include phone-large {
            flex-direction: column-reverse;
        }
    }
    &__img, &__col {
        display: flex;
        width: 100%;
    }
    &__img {
        align-items: flex-end;
        position: relative;
        max-width: 710px;
        @include table--min {
            min-width: 540px;
        }
        img {
            max-width: unset;
            object-fit: contain;
            pointer-events: none;
            @include phone-large--min {
                position: absolute;
                right: -72px;
            }
            @include phone-table {
               // bottom: -68px;
                max-width: 704px;
            }
            @include phone-large {
                max-width: 100%;
               // margin-bottom: -9.6%;
            }
        }
    }
    &__col {
        flex-direction: column;
        justify-content: center;
        max-width: 600px;
        padding: 64px 0;
    }
    h2 {
        color: var(--text-primary);
        font-weight: 700;
        font-size: max(24px, min(3vw, 44px));
        margin-bottom: 50px;
        @include phone-large {
            font-size: max(24px, min(6vw, 44px));
        }
    }
    .btn {
        width: 100%;
        max-width: 344px;
        align-self: flex-start;
    }
}
.customers {
    margin-top: 100px;
    padding-bottom: 32px;
    overflow: hidden;
    &__progress {
        display: flex;
        align-self: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 22px;
        width: calc(100% + 58px * 2);
        &.active {
            .progress-circle {
                &--one {
                    .progress-circle__line {
                        stroke-dashoffset: 46%;
                    }
                }
                &--two {
                    .progress-circle__line {
                        stroke-dashoffset: 42%;
                    }
                }
                &--three {
                    .progress-circle__line {
                        stroke-dashoffset: 70%;
                    }
                }
            }
        }
    }
    .wrapper-large {
        h1 {
            font-size: max(48px, min(4.7vw, 70px));
            color: var(--color-primary);
            text-align: center;
            @include table--min {
                letter-spacing: -.5px;
            }
        }
    }
    .wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
    }
    .h1 {
        margin-bottom: 30px;
    }
    .h2 {
        font-size: max(20px, min(3vw, 32px));
    }
}
.progress-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 306px;
    min-width: 306px;
    height: 306px;
    padding: 48px 32px 32px;
    margin: 58px 58px 0;
    border-radius: 50%;
    background-color: var(--bg-secondary);
    font-family: var(--font-secondary);
    color: var(--text-primary);
    text-align: center;
    position: relative;
    &__number {
        font-size: 85px;
        font-weight: 700;
        line-height: 1;
    }
    &__text {
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
    }
    &__line {
        position: absolute;
        width: 342px;
        height: 342px;
        stroke-dashoffset: 282%;
        stroke-dasharray: 282%;
        transform: rotate(-90deg);
        transition: 3s;
    }
}
