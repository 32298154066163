.campaigns {
    &.black {
        padding: max(70px, min(14.8vw, 150px)) 0 max(50px, min(5.9vw, 60px));
        background-color: var(--bg-secondary);
        color: var(--text-primary);
    }
    &:not(.black) {
        padding-top: max(50px, min(9.8vw, 100px));
    }
    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 46px;
    }
    &__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 32px 12px;
        margin-bottom: 50px;
        @include table {
            grid-template-columns: repeat(2, 1fr);
        }
        @include phone-small {
            grid-template-columns: repeat(1, 1fr);
        }
      &-no-image {
        align-self: center;
        font-style: italic;
        color: #c0c0c0;
      }
    }
    &__box {
        display: flex;
        flex-direction: column;
        color: currentColor;
        cursor: pointer;
        @include hover {
            &:hover {
                img {
                    transform: scale(1.1);
                }
              &:hover  > .campaigns__img > .campaign-box__hover {
                visibility: visible;
              }
            }
        }
        &:active {
            img {
                transform: scale(1.1);
            }
        }
    }
    &__img {
        display: flex;
        height: 150px;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        border-radius: 5px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
            transform-origin: 50%;
            transition: .35s ease;
        }
    }
    &__title {
        font-weight: 500;
        line-height: 1.5;
        margin-top: 16px;
        cursor: pointer;
    }
    &__number {
        display: flex;
        align-items: center;
        margin-left: auto;
        padding-left: 16px;
        svg {
            margin-right: 10px;
        }
        span {
            font-weight: 500;
            font-family: var(--font-secondary);
            b {
                font-weight: 500;
                @include phone-small {
                    display: none;
                }
            }
        }
    }
    h1 {
        margin-bottom: max(20px, min(3vw, 30px));
    }
    .btn {
        margin: 0 auto;
    }
}
.fashionista-hero {
    padding-top: max(70px, min(14.8vw, 150px));
    background-color: var(--bg-secondary);
    color: var(--text-primary);
  &__title-box {
    display: flex;
    justify-content: space-between;
  }
  &__cont {
        display: flex;
        margin-bottom: 92px;
        @include table {
            flex-direction: column;
          gap: 150px;
        }
        @media screen and (max-width: 580px) {
            margin-bottom: 50px;
        }
    }
    &__img {
        display: flex;
        max-width: 580px;
        width: 100%;
        min-height: 300px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @include table {
            min-height: unset;
            max-height: 300px;
        }
    }
    &__block {
        display: flex;
        flex-direction: column;
        max-width: 586px;
        width: 100%;
        position: relative;
        @include table--min {
            margin-left: 30px;
            min-width: 490px;
        }
        @include table {
            margin-top: max(20px, min(3vw, 30px));
        }
        @media screen and (max-width: 580px) {
            padding-top: 74px;
        }
    }
    &__row {
        display: flex;
        margin-bottom: 34px;
        position: relative;
        @media screen and (max-width: 580px) {
            flex-wrap: wrap;
            justify-content: space-between;
            &:first-child {
                margin-bottom: 135px;
                .fashionista-hero__colum {
                    &:nth-child(2) {
                        margin-right: 0;
                    }
                    &:nth-child(3) {
                        position: absolute;
                        top: calc(100% + 20px);
                        right: 0;
                    }
                }
            }
            &:nth-child(2) {
                margin-bottom: 20px;
                .fashionista-hero__colum {
                    &:first-child {
                        position: absolute;
                        bottom: calc(100% + 20px);
                    }
                    &:nth-child(2) {
                        text-align: left;
                    }
                }
            }
        }
    }
    &__colum {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 581px) {
            width: 100%;
            &:nth-child(1) {
                max-width: 254px;
                min-width: 180px;
            }
            &:nth-child(2), &:nth-child(3) {
                max-width: 150px;
            }
        }
        &:not(:last-child) {
            margin-right: 16px;
        }
        &:nth-child(2), &:nth-child(3) {
            text-align: right;
        }
    }
    &__text {
        font-size: max(18px,min(1.6vw, 20px));
        color: var(--text-secondary);
        margin-bottom: 10px;
        @media screen and (max-width: 580px) {
            font-size: max(18px,min(5.4vw, 20px));
        }
    }
    &__number {
        font-size: max(36px,min(3.8vw, 48px));
        @media screen and (max-width: 580px) {
            font-size: max(36px,min(12.8vw, 48px));
        }
        &.color {
            color: var(--color-secondary);
        }
    }
    &__group-btn {
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        .btn-text {
            &:first-child {
                @media screen and (max-width: 580px) {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            &:last-child {
                @media screen and (max-width: 580px) {
                    margin-left: auto;
                }
            }
        }
    }
    .h1 {
        margin-bottom: 50px;
        b:not(.hr ) {
            color: var(--color-primary);
            font-weight: 600;
        }
        .hr {
            color: currentColor;
            font-weight: 400;
        }
    }
    .btn-text {
        font-weight: 500;
    }
    .tabs {
        @include phone-large {
            display: none;
        }
        &__btn {
            width: calc(100% / 5);
        }
    }
    .select {
        max-width: 240px;
        margin: 0 auto;
        @include phone-large--min {
            display: none;
        }
        select {
            height: 60px;
            background-color: var(--bg-primary);
            text-align: center;
        }
    }
}
.fashionista-filter {
    + .wrapper {
        @include phone-small {
            padding: 0;
        }
    }
    .wrapper-large {
        padding-top: 60px;
        padding-bottom: 45px;
        background-color: var(--bg-primary);
    }
    &__cont {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1196px;
        margin: 0 auto;
        @include phone-large {
            flex-direction: column;
        }
    }
    &__colum {
        display: flex;
        flex-direction: column;
        width: 100%;
        &:nth-child(1) {
            max-width: 636px;
            margin-right: 32px;
            @include phone-large {
                margin: 0 0 20px;
            }
        }
        &:nth-child(2) {
            max-width: 394px;
        }
    }
    &__group {
        display: flex;
        align-items: center;
    }
    &__title {
        color: var(--text-secondary);
        margin-bottom: 10px;
    }
    .btn-icon {
        margin-left: 24px;
    }
}
.fashionista-tab {
    display: flex;
    &__btn {
        display: flex;
        width: 54px;
        height: 54px;
        color: var(--text-main);
        cursor: pointer;
        @include ti-1;
        svg {
            width: 100%;
            height: 100%;
        }
        &:not(:last-child) {
            margin-right: max(10px, min(3vw, 30px));
        }
        @include hover {
            &:hover {
                color: var(--color-primary);
            }
        }
        &:active {
            color: var(--color-primary);
        }
        &.active {
            color: var(--color-primary);
        }
    }
}
.fashionista-cap {
    &.black-bg:after {
        height: 676px;
    }
    .wrapper {
        padding-top: max(70px, min(14.8vw, 150px));
        @include phone-small {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .h1 {
        margin-bottom: 0;
        color: var(--text-primary);
        @include phone-small {
            padding: 0 29px;
        }
    }
    .btn {
        margin: 50px auto 0;
    }
}
.fashionista-analytics {
    .h1 {
        @media screen and (max-width: 580px) {
            .hr {
                display: none;
            }
        }
    }
    .fashionista-hero {
        padding-bottom: 150px;
        @include phone-large {
            padding-bottom: 64px;
        }
    }
    .fashionista-hero__cont {
        @include table {
            margin-bottom: 0;
        }
    }
    .fashionista-hero__block {
        @media screen and (max-width: 580px) {
            flex-direction: row;
            justify-content: space-between;
            padding-top: 0;
            margin-top: 50px;
        }
    }
    .fashionista-hero__row {
        justify-content: space-between;
        @media screen and (max-width: 580px) {
            flex-direction: column;
            justify-content: unset;
            &:first-child {
                margin: 0;
                .fashionista-hero__colum {
                    text-align: left;
                }
            }
            &:nth-child(2) {
                margin: 0;
                .fashionista-hero__colum {
                    text-align: right;
                    margin-right: 0;
                    &:first-child {
                        max-width: 146px;
                    }
                }
            }
        }
        &:nth-child(2) {
            margin: auto 0 0;
            .fashionista-hero__colum:first-child {
                position: relative;
            }
        }
    }
    .fashionista-hero__colum {
        width: auto;
        min-width: unset;
        max-width: unset;
        @media screen and (max-width: 580px) {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}
.campaign-review{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 100px 0;
  flex-direction: column;

  .post-info{
    justify-content: center;
    position: relative;
  }
}
.insights-link, .insights-link_post-feedback{
  align-self: flex-start;
  &_post-feedback {
    position: absolute;
    bottom: 120px;
  }

  img{
    width: 20px;
    float: left;
    padding: 5px 5px 0 0;
  }
  a{
    float: left;
    color: var(--color-primary);
    &:hover {
      text-decoration: underline;
    }
  }
  &.three-items{
    margin-bottom: 80px;
  }
}
.campaign-review{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0 100px 0;
  flex-direction: column;

  .post-info{
    justify-content: center;
    position: relative;
  }
}

.post-tab-navigation-buttons{
  padding-top: 50px;
  display: flex;
  justify-content: center;
  width: 100%;

  .post-tab__navigate-button{
    font-size: 21px;
    color: var(--tertiary-text-color);
  }
}
.post-info {
  display: flex;
  min-height: 500px;
  &__colum {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-wrap: break-word;
    &:nth-child(1) {
      max-width: 352px;
      margin-right: 50px;
      min-width: 250px;
    }
    &:nth-child(2), video {
      max-width: 406px;
    }
  }
  &__btn-group {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  &__img {
    height: 53vw;
    max-height: 530px;
    margin-bottom: 20px;
    object-fit: cover;
  }
  &__submitted {
    display: flex;
    margin-top: 16px;
    padding-top: 20px;
    font-size: 10px;
    line-height: 1;
    b {
      font-weight: 600;
      margin-left: 6px;
    }
    &:nth-child(3) {
      padding: unset;
      border-top: unset;
    }
  }
}

.feedback-block {
  background-color: var(--gray-light);
  &__middle {
    background-color: var(--white);
  }
}
.feedback-info {
  background-color: var(--gray-light);
}
.feedback-info, .modal-post-info {
  &__avatar {
    border: 1px solid #DADADA;
  }
  &__title {
    color: var(--green);
  }
}
.feedback-price, .post-info-price-all {
  background-color: var(--blue-light);
}
.modal-post-info {
  margin-bottom: 46px;
}
.feedback-info, .modal-post-info {
  display: flex;
  align-items: center;
  &__avatar {
    width: 74px;
    height: 74px;
    min-width: 74px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__group {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 4px 0;
  }
  &__title {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 6px;
    word-break: break-all;
  }
  &__subtitle {
    font-size: 14px;
    line-height: 18px;
    b {
      font-weight: 600;
    }
  }
  &__platform {
    width: 34px;
    min-width: 34px;
    height: 34px;
    margin-left: 10px;
  }
}
.download-link{
  a{
    float: left;
    &:hover {
      text-decoration: underline;
    }
  }
  &.new-tab-page{
    margin-bottom: 20px;
  }
}
.new-tab-link{
  a{
    float: left;
    &:hover {
      text-decoration: underline;
    }
  }
}
.post-block{
  &__actions-wrapper {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__info {
    font-size: 12px;
    line-height: 1;
    &:first-child {
      margin-bottom: 4px;
    }
    b {
      font-weight: 600;
    }
    a {
      font-weight: bold;
      padding-top: 5px;
      font-size: 14px;
      display: flex;
      color: var(--color-primary);
    }
  }
}
