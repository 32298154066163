*, *:before, *:after {
    outline: none !important;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent !important;
    -moz-tap-highlight-color: transparent !important;
    -o-tap-highlight-color: transparent !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    transition: 5000000s ease-in-out 0s;
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: var(--font-primary);
    color: var(--text-main);
    background-color: var(--bg-secondary);
    font-size: 16px;
    line-height: 1.25;
    min-width: 320px;
    &.scroll-hidden {
        overflow: hidden;
    }
    &.home {
        header {
            .btn {
                max-width: 300px;
                width: 100%;
            }
        }
        main {
            margin-top: unset;
        }
    }
}
main, footer {
    background-color: var(--bg-main);
}
main {
    margin-top: 100px;
    padding-bottom: 118px;
    @include table {
        margin-top: 80px;
    }
}
input, textarea {
    font-family: var(--font-primary);
}
h1, h2, h3, span, p {
    cursor: default;
}
ul, ol {
    list-style: none;
}
a {
    text-decoration: unset;
    & * {
        cursor: pointer;
    }
}
input:invalid {
    box-shadow: none;
}
img {
    max-width: 100%;
}
button {
    border: none;
    cursor: pointer;
    span, p {
        cursor: pointer;
    }
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
.cdk-overlay-container {
  z-index: 10000!important;
}
.mat-form-field-underline {
  display: none!important;
}
.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0!important;
  padding-left: 10px;
  width: 95%;
}

mat-form-field {
  width: 95%;
}
.errorForm {
  border: 1px solid red!important;
}
.currencyItem {
  justify-content: space-between;
  //display: flex;
  //justify-content: space-between;
}

input {
  &::-webkit-input-placeholder { color: var(--text-secondary); }
  &:-ms-input-placeholder { color: var(--text-secondary); }
  &::-ms-input-placeholder { color: var(--text-secondary); }
  &::placeholder { color: var(--text-secondary); }
}
