.feedback {
  padding: 50px 0;
  &__title {
    text-align: center;
    margin-top: 20px;
  }
    h2 {
      font-size: 38px;
    }
    &__cont {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 6px;
        padding: 50px 0;
    }
    .wrapper-big {
        position: relative;
    }
    .client-reviewed {
      display: flex;
      min-height: 400px;
      justify-content: center;
      h3 {
        color: var(--text-main);
        margin-top: 100px;
      }
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        width: calc(100% - 64px);
        object-fit: cover;
        border-radius: 10px;
        z-index: -1;
        margin: 0 32px;
        height: 265px;
        box-shadow: 0px 15px 25px -6px rgba(27, 0, 46, 0.5);
    }
    .btn {
        margin: 0 auto;
      transition: all .1s ease-in;
      color: var(--text-primary);
      background-color: var(--color-primary);

      &:hover {
        background-color: var(--color-primary-hover);
      }

      &__text {
        font-size: 16px;
      }
    }
}
.feedback-block {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 14px 14px 20px;
    position: relative;
    &__middle {
      height: 100%;
        box-shadow: 0px 0px 25px -10px rgba(27, 0, 46, 0.15);
        border-radius: 5px 5px 0px 0px;
        padding: 24px 24px 34px;
        display: flex;
        flex-direction: column;
    }
    &__img {
        max-height: 530px;
        height: 34vw;
        object-fit: cover;
        margin-bottom: 16px;
    }
    &__description {
        font-size: 12px;
        line-height: 12px;
        b {
            font-weight: 600;
        }
    }
    &__bottom {
        display: flex;
        justify-content: space-evenly;
        padding-top: 80px;
        margin-top: auto;
      .disabled {
        opacity: 0.3;
      }
    }
  &__btn {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    &.approve {
      color: var(--color-success);
    }
    &.change {
      color: var(--color-warning);
    }
    &.decline {
      color: var(--color-danger);
    }

    transition: all .1s ease-in;
    &:hover {
      filter: brightness(0.8);
    }
  }
}
.feedback-info {
  margin-top: auto;
    padding: 12px 18px;
    margin-bottom: 4px;
}
.feedback-block__middle .feedback-info, .modal-post-info {
    display: flex;
    align-items: center;
    &__avatar {
        width: 74px;
        height: 74px;
        min-width: 74px;
        margin-right: 10px;
        border-radius: 50%;
        object-fit: cover;
    }
    &__group {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 4px 0;
    }
    &__title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 6px;
        word-break: break-all;
    }
    &__subtitle {
        font-size: 14px;
        line-height: 18px;
        b {
            font-weight: 600;
        }
    }
    &__platform {
      align-self: flex-start;
        width: 34px;
        min-width: 34px;
        height: 34px;
        margin-left: 10px;
    }
}
.feedback-price, .post-info-price-all {
    display: flex;
    justify-content: space-between;
    padding: 10px 18px;
    margin-bottom: 16px;
    &__text {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        &:first-child {
            margin-right: 10px;
        }
    }
}
