.article {
    padding-top: max(70px, min(14.8vw, 150px));
    overflow: hidden;
    &.black-bg:after {
        height: 358px;
    }
    h1 {
        color: var(--text-primary);
        margin-bottom: 50px;
    }
    .h1 {
        margin-bottom: 42px;
    }
    &__wrapper {
        display: flex;
        justify-content: center;
        background-color: var(--bg-primary);
        padding: 42px 32px;
        @include phone-large {
            margin: 0 -29px;
            padding-left: 29px;
            padding-right: 29px;
        }
    }
    &__content {
        width: 100%;
        max-width: 950px;
        font-size: 20px;
        h1, h2, h3, h4, h5, h6, b, .h1 {
            color: var(--text-main);
        }
        p {
            color: var(--text-secondary);
            margin-block-start: 1em;
            margin-block-end: 1em;
        }
        &:first-child {
            h1, h2, h3, h4, h5, h6, b, .h1 {
                margin-top: 0;
            }
        }
    }
}
