.modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    background-color: var(--bg-primary);
    box-shadow: 0px 10px 25px -25px rgb(27 0 46 / 25%);
    margin: auto 0;
    position: relative;
    @media screen and (min-width: 481px) {
        align-items: center;
    }
    &--padding {
        padding: 100px 56px 50px;
        @include phone-large {
            padding: 100px 29px 50px;
        }
    }
    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 36px;
        height: 36px;
        display: flex;
        background-color: transparent;
        @include ti-1;
        @include phone-large {
            top: 24px;
            right: 30px;
        }
        @include hover {
            &:hover  {
                color: var(--color-primary);
            }
        }
        &:active  {
            color: var(--color-primary);
        }
    }
    &__wrapper {
        width: 100%;
        max-width: 954px;
        margin: 0 auto;

          input {
            border-color: var(--color-primary);
          }

    }
    &__text {
        font-size: 20px;
        line-height: 28px;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        b {
            font-weight: 500;
        }
    }
    .h1 {
        font-size: max(36px, min(3.8vw, 48px));
        margin-bottom: 20px;
        @media screen and (min-width: 481px) {
            text-align: center;
        }
    }
    .h2 {
        b {
            color: var(--text-main);
        }
    }
    .btn--modal {
        width: 100%;
        max-width: 344px;
        margin-top: 50px;
    }
}
.modal-brand {
    @include phone-large--min {
        padding: 50px 32px;
    }
    @include phone-large {
        padding: 100px 24px 60px;
    }
    @include phone-small {
        padding: 100px 30px 60px;
    }
    &__cont {
        display: flex;
        flex-direction: column;
        max-width: 990px;
        margin: 0 -15px;
        @media screen and (max-width: 1087px) and (min-width: 726px) {
            max-width: 660px;
        }
        @media screen and (max-width: 725px) {
            max-width: 330px;
        }
        @include phone-small {
            align-self: center;
        }
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__box {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        margin: 0 15px 30px;
    }
    &__img {
        display: flex;
        width: 100%;
        height: 350px;
        object-fit: cover;
        padding: 25px;
        border: 1px solid currentColor;
    }
    &__bottom {
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        margin-top: -25px;
    }
    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        align-self: center;
        margin-top: 18px;
    }
    &__price {
        display: flex;
        margin-top: 5px;
        span {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 20px;
            &:nth-child(1) {
                flex: 1;
                background-color: var(--bg-quaternary);
                color: var(--text-primary);
                min-width: 120px;
                font-size: 12px;
                margin-right: 5px;
            }
            &:nth-child(2) {
                white-space: nowrap;
                min-width: 100px;
                background-color: var(--bg-tertiary);
                font-weight: 500;
                justify-content: right;
            }
        }
    }
    &__text {
        padding: 0 20px;
        font-size: 10px;
        line-height: 20px;
        color: var(--text-secondary);
    }
    .form__box {
        margin-bottom: 6px;
    }
    .checked-circle {
        margin-bottom: 10px;
    }
}
.modal-post {
    @include phone-large--min {
        padding: 40px 32px 75px;
    }
    @include phone-large {
        padding: 100px 24px;
    }
    @include phone-small {
        padding: 100px 30px;
    }
    .h1 {
        @media screen and (min-width: 481px) {
            margin-bottom: 10px;
        }
    }
    &__subtitle {
        color: var(--text-secondary);
        margin-bottom: 18px;
        @include phone-small {
            margin-bottom: 50px;
            font-size: 20px;
        }
    }
    &__block {
        width: 100%;
        max-width: 808px;
        padding: 24px;
        border: 1px solid currentColor;
        @include phone-large {
            max-width: 320px;
            padding: 24px 20px 50px;
        }
        @include phone-small {
            align-self: center;
        }
    }
    &__box {
        display: flex;
        margin-bottom: 24px;
        @include phone-large {
            flex-direction: column;
            margin-bottom: 30px;
        }
    }
    &__img {
        width: 100%;
        height: 53vw;
        max-height: 530px;
        object-fit: cover;
        @include phone-large {
            width: 100%;
            min-width: 100%;
        }
    }
    &__group {
      flex-grow: 1;
        display: flex;
        flex-direction: column;
      width: 50%;
        padding-left: 24px;
        position: relative;
        @include phone-large {
            padding: 20px 0 0;
          width: 100%;
        }
        &-content{
          flex-grow: 1;
          width: 50%;
          @include phone-large {
            width: 100%;
          }
        }
    }
    .post-card-desc {
        margin: 0;
    }
    .post-card-middle__author {
        margin-bottom: 15px;
    }
    .post-card-middle__group {
        width: calc(100% - 80px);
        padding-left: 8px;
        padding-right: 8px;
    }
    .post-card-top__icon {
        margin-left: auto;
    }
    .post-card-price {
        margin-top: 5px;
    }
    .post-card__btn-group.large {
      display: flex;
      align-items: center;
        @include phone-large {
            margin-top: 30px;
        }
    }
    .modal-brand__text {
        margin-bottom: auto;
    }
}
.modal-wallet {
    @include phone-large--min {
        padding: 40px 32px 50px;
    }
    @include phone-large {
        padding: 100px 24px 58px;
    }
    @include phone-small {
        padding: 100px 30px 58px;
    }
    .h1 {
        @media screen and (min-width: 481px) {
            margin-bottom: 10px;
        }
    }
    &__subtitle {
        color: var(--text-secondary);
        margin-bottom: 18px;
        @include phone-small {
            margin-bottom: 50px;
            font-size: 20px;
        }
    }
    &__middle {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border: 1px solid currentColor;
        padding: 42px;
        min-height: 250px;
        max-width: 1198px;
        width: 100%;
        @include phone-large {
            padding: 24px 20px;
        }
        @include phone-table {
            padding: 92px 38px 38px;
        }
    }
    &__block {
        display: flex;
        align-items: flex-end;
        max-width: 954px;
        width: 100%;
        @include table {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__available {
        display: flex;
        flex-direction: column;
        min-width: 224px;
        margin-right: 20px;
        @include phone-large {
            margin: 0 0 40px;
        }
        span {
            font-size: 20px;
            margin-bottom: 5px;
        }
        p {
            font-size: 48px;
            color: var(--color-primary);
            white-space: nowrap;
            line-height: 55px;
            @include phone-small {
                font-size: max(36px, min(3.8vw, 48px));
            }
        }
    }
    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 954px;
        width: 100%;
        margin-top: 18px;
    }
    &__text {
        font-size: 14px;
        align-self: flex-start;
        color: var(--text-quaternary);
        margin: 30px 0 30px 0;
    }
    .form__box {
        flex: 1;
        @include table {
            width: 100%;
        }
    }
    .form__input {
        @include phone-large--min {
            padding: 0 30px;
        }
    }
}
.modal-reg {
  position: relative;
    padding: 50px 56px;
    @include phone-large {
        padding: 40px 29px 50px;
    }
    &__header {
        width: 100%;
        max-width: 954px;
        margin-bottom: 50px;
        .h1 {
            text-align: left;
            margin-bottom: 0;
        }
    }
    &__main {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1196px;
      .test-plan-item {
        order: 2;
        margin-top: 20px;
      }
      .corporate-plan-item {
        order: 1;
      }
    }
    &__row {
        display: flex;
      justify-content: flex-start;
      &.align-center {
        align-items: center;
      }
      gap: 5px;
        @include table {
            flex-direction: column;
        }
    }
    &__caption-box {
        display: flex;
        margin-bottom: 20px;
        @include phone-large--min {
            justify-content: space-between;
            align-items: center;
        }
        @include phone-large {
            flex-direction: column-reverse;
        }
    }
    &__step-text {
        font-family: var(--font-secondary);
        font-size: max(20px, min(3.2vw, 24px));
        font-weight: 700;
        line-height: 1;
        @include phone-large {
            margin-bottom: 40px;
        }
    }
    &__col {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-end;
        &:nth-child(1) {
            //max-width: 467px;
            @include table--min {
                margin-right: 20px;
            }
        }
        &:nth-child(2) {
            max-width: 582px;
        }
        @include table {
            margin-bottom: 26px;
        }
    }
    &__input-group {
        display: flex;
        @include phone-large {
            flex-direction: column;
        }
        .form__box {
            width: 100%;
            &:nth-child(1) {
                @include phone-large--min {
                    //max-width: 224px;
                    margin-right: 20px;
                }
            }
            @include phone-large {
                margin-bottom: 26px;
            }
        }
    }
    .btn--modal {
        align-self: center;
    }
    .checked {
        margin-top: 16px;
        max-width: 312px;
    }
  &__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn--modal {
      width: 45%;
      margin: 50px 0 0;
    }
  }

  &__promocode {
    margin-bottom: 10px;
  }
  &__promocode-input {
    width: 400px;
    height: 50px;
    padding: 0 10px;
    color: var(--text-secondary);
    background-color: #EFF0F6;
    border: none;
    font-size: 16px;
  }
  &__placeholder {
    color: #CFCFCF;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
}
.modal-message {
    .h1 {
        font-size: max(34px, min(3.8vw, 48px));
    }
}
.modal-payment {
    &__group {
        display: flex;
        flex-direction: column;
        margin-top: 36px;
    }
    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        margin-bottom: 14px;
    }
    &__price {
        font-size: 48px;
        line-height: 1;
        color: var(--color-primary);
        word-break: break-word;
    }
    &__group-btn {
        display: flex;
        margin-top: 50px;
        justify-content: center;
        @include phone-large {
            flex-direction: column;
        }
        .pay-pal-btn {
            @include phone-large--min {
                margin-right: 20px;
            }
            @include phone-large {
                margin-bottom: 16px;
            }
        }
        .btn {
            width: 100%;
            max-width: 400px;
        }
    }
}
.modal-login {
    .btn-text {
        height: 24px;
        &.btn--secondary {
            @include hover {
                &:hover {
                    color: var(--text-main) !important;
                }
            }
            &:active {
                color: var(--text-main) !important;
            }
        }
    }
    .modal-reg__caption-box {
        .btn-text {
            @include phone-large {
                margin-bottom: 40px;
            }
        }
    }
    .modal-reg__col {
        justify-content: flex-start;
        max-width: 582px;
        .btn-text {
            align-self: flex-end;
        }
        &:nth-child(2) {
            .form__box:not(:last-child) {
                margin-bottom: 10px;
                @include phone-large {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
.modal-article {
    max-height: calc(100vh - 96px);
    &__block {
        font-size: 20px;
        line-height: 28px;
        p, span {
            &:first-child {
                margin-block-start: 0em;
            }
            &:last-child {
                margin-block-end: 0em;
            }
        }
        p, span {
            margin-block-start: 1em;
            margin-block-end: 1em;
        }
    }
    .modal__wrapper {
        max-width: 922px;
    }
    .modal-reg__main {
        overflow: hidden auto;
        padding-right: 12px;
        &::-webkit-scrollbar {
            background-color: var(--bg-tertiary);
            width: 20px;
            @include phone-large {
                width: 10px;
            }
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-primary);
        }
    }
}
.choose-plan {
    display: flex;
    justify-content: center;
    background-color: var(--bg-secondary);
    padding: 16px 32px;
    margin-bottom: 5px;
    color: var(--text-primary);
    min-height: 90px;
    cursor: pointer;
    position: relative;
    @include ti-1;
    &:not(:first-child) {
        margin-top: 10px;
    }
    @media screen and (min-width: 768px) and (max-width: 1180px) {
        width: calc(100% + 64px);
        margin: 0 -32px;
        margin-bottom: 5px;
    }
    @include phone-small {
        padding: 15px 20px 12px;
    }

  &.test-plan {
    background-color: var(--text-secondary);

    .choose-plan__price {
      color: white;
    }
  }
    &.active {
        color: var(--text-main);
        background-color: var(--color-primary);
        .choose-plan__price {
            color: currentColor;
        }
      .choose-plan__contact-us {
        color: var(--text-main);
      }
      .choose-plan__title {
        span {
          color: white;
        }
      }
        .choose-plan__icon {
            opacity: 1;
        }
        .reward-box__info {
            color: var(--text-primary);
        }
        .tooltip {
            background-color: var(--bg-secondary);
            &:after {
                border-top-color: var(--bg-secondary);
            }
            &.left:after {
                border-left-color: var(--bg-secondary);
            }
        }
    }
    &__wrapper {
        display: flex;
        width: 100%;
        max-width: 954px;
        @media screen and (max-width: 1180px) {
            position: relative;
        }
        @include phone-large--min {
            align-items: center;
            justify-content: space-between;
          gap: 80px;
            padding-right: 64px;
        }
        @include phone-large {
            flex-direction: column;
        }
    }
    &__title {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
        span {
            font-size: 14px;
            font-weight: 400;
            padding-left: 6px;
            padding-bottom: 3px;
          color: var(--text-secondary);
        }
    }
    &__price {
        color: var(--text-secondary);
        @include ti-1;
        @include phone-large {
            margin-top: 22px;
        }
    }
    &__icon {
        position: absolute;
        right: 0;
        top: 50%;
        width: 32px;
        height: 32px;
        object-fit: contain;
        transform: translateY(-50%);
        transition: opacity 0.1s linear;
        opacity: 0;
        @media screen and (min-width: 1181px) {
            right: 40px;
        }
    }
    .reward-box__info {
        max-width: unset;
    }
    span, p {
        cursor: pointer;
    }
  &__column {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &.promo-column {
      margin-top: 10px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 10px;

    .promo-btn {
      cursor: pointer;
      color: var(--text-secondary);
      &:hover {
        color: var(--text-main);
      }
    }
  }

  &__checkbox {
    display: block;
    width: 15px;
    height: 15px;
    color: var(--text-secondary);

    cursor: pointer;
    background-color: white;
    outline: solid 2px var(--color-primary) !important;
    border: solid 2px white;

    &:checked {
      background-color: var(--color-primary);
    }
  }

  &__promo-msg{
    font-size: 16px;

    &.success-msg {
      color: var(--color-success);
    }
    &.error-msg {
      color: var(--color-danger);
    }
  }

  &__total-price {
    margin-top: 20px;
    padding: 10px 10px;
    align-self: flex-start;

    font-size: 24px;
    color: var(--text-main);
  }

  &__contact-us {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--color-primary);

    &:hover {
      text-decoration: underline;
    }
  }
}
.overlay, .overlay-menu, .ngneat-dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background: rgb(75, 130, 160, 0.5)!important;
  backdrop-filter: blur(4px);
  display: none;
  overflow: auto;
}
.ngneat-dialog-content {
  overflow: auto !important;
}
.ngneat-dialog-content p {
  text-align: center;
  font-size: 18px !important;
}
.dialog-footer {
  justify-content: space-around!important;
}
.ngneat-dialog-primary-btn.btn-success{
  background: var(--color-primary)!important;
}
.ngneat-dialog-secondary-btn.btn-cancel{
  border-color: var(--color-primary)!important;
  color: var(--color-primary)!important;
}

.form__box.disabled {
  opacity: 0.4;
}

.modal-close-btn {
  width: 24px;
  height: 24px;

  margin-top: 10px;
  margin-right: 10px;
  float: right;

  cursor: pointer;

  &:hover {
    path {
      fill: var(--color-primary);
    }
  }
}
