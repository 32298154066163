:root {
    /* fonts */
    --font-primary: 'Inter', sans-serif;
    --font-secondary: 'Poppins', sans-serif;
    --font-tertiary: 'OpenSans', sans-serif;

    /* colors */
    --color-primary: #4BD5CA;
    --color-primary-contrast: #020D13;
    --color-primary-hover: #42bbb2;
    --color-secondary: #4B82A0;

    --color-success: #4BD559;
    --color-warning: #ecdc03;
    --color-warning-02: rgba(213, 207, 75, 0.2);
    --color-danger: #D54B4B;
    --color-danger-hover: #ce3232;


    /* text colors */
    --text-main: #020D13;
    --text-primary: #FFFFFF;
    --text-secondary: #8F8E93;
    --text-tertiary: #EFF0F6;
    --text-quaternary: #CDCDCD;


    /* bg colors */
    --bg-main: #F8F8F8;
    --bg-primary: #FFFFFF;
    --bg-secondary: #020D13;
    --bg-tertiary: #EFF0F6;
    --bg-quaternary: #95A1A8;


    /* buttons */
    --btn-primary: var(--color-primary);
    --btn-danger: var(--color-danger);
    --btn-danger-hover: var(--color-danger-hover);
    --btn-primary-contrast: var(--color-primary-contrast);
    --btn-primary-hover: var(--color-primary-hover);
    --btn-secondary: var(--color-primary-contrast);
    --btn-secondary-contrast: var(--text-primary);
    --btn-secondary-hover: #1b252b;
    --btn-disabled: #333333;
    --btn-disabled-contrast: #bec6cd;
    --btn-cancel: #DADADA;
    --btn-cancel-hover: var(--color-danger);


    /* table */
    --table-th: #F2F2F2;
    --table-td: #FAFAFA;

    --campaign-selected-type: #3EABA2;
    --campaign-selected-type-voucher: #60BDB6;
  --campaign-unselected-type: #ababab;


    /* form */
    --toggle: #D4D4D6;
    --toggle-contrast: #FFFFFF;
    --toggle-text: var(--text-secondary);
    --toggle-shade: var(--color-primary);
    --valid-error: #EE2B37;
    --valid-error-shade: #F99696;
    --valid-warning: #EEAC2B;
    --valid-warning-shade: #F9D196;


    /* all */
    --bg-header: rgb(2 13 19 / 80%);
    --overlay: rgba(2, 13, 19, 0.5);
    --paypal-button: #FFC339;
    --paypal-button-hover: #ffba1a;
    --current-brand-border: #B706F5;
}
