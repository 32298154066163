.invite {
    padding-top: max(70px, min(14.8vw, 150px));
    overflow: hidden;
    &.black-bg:after {
        height: 358px;
    }
    h1 {
        color: var(--text-primary);
        @include phone-small {
            margin-bottom: 20px;
        }
    }
    .h1 {
        margin-bottom: 42px;
        @include phone-large {
            margin-bottom: 50px;
        }
    }
    .wrapper-large {
        display: flex;
        justify-content: center;
        background-color: var(--bg-primary);
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &__title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 50px;
        @include phone-small {
            flex-direction: column;
            align-items: unset;
        }
    }
    &__cont {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1196px;
    }
    &__code {
        font-family: var(--font-secondary);
        font-size: max(30px, min(4.7vw, 48px));
        color: var(--color-secondary);
        word-break: break-word;
        margin-bottom: 42px;
        span {
          cursor: pointer;
        }
        @include phone-large {
            margin-bottom: 30px;
        }
    }
    .form__box {
        max-width: 588px;
    }
    .btn {
        margin-top: 50px;
        align-self: flex-start;
    }
}
