.fly-info {
    &__text {
        margin-bottom: 30px;
        a {
            color: var(--primary-text-color);
        }
    }
    &__title {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 30px;
        line-height: 1.2;
    }
    &__cont {
      ol {
        counter-reset: item;
        ul {
          counter-reset: item;
          margin-left: 30px;
        }
      }
      li { display: block }
      li:before { content: counters(item, ".") " "; counter-increment: item }
    }

}

.delete-account {
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__title {
    font-size: 48px;
    font-weight: 700;
  }

  &__text {
    font-size: 20px;
  }

  &__cont {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }
}
