.wrapper {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @include phone-large--min {
        padding: 40px 32px 20px;
    }
    @include phone-large {
        padding: 0 29px;
    }
}
.wrapper-large {
    width: 100%;
    max-width: 1504px;
    margin: 0 auto;
    @include phone-large--min {
        padding: 0 32px;
    }
    @include phone-large {
        padding: 0 29px;
    }
}
.black-bg {
    position: relative;
    z-index: 1;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        background-color: var(--bg-secondary);
        z-index: -1;
        transform: translateX(-50%);
        pointer-events: none;
    }
}

h1, .h1 {
    font-size: max(36px, min(4.7vw, 48px));
    font-family: var(--font-secondary);
    font-weight: 700;
    line-height: 1;
    word-break: break-word;
}
h2, .h2 {
    font-size: max(20px, min(3vw, 30px));
    font-weight: 400;
    color: var(--text-secondary);
    word-break: break-word;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 224px;
    height: 54px;
    min-height: 54px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 20px;
    font-family: var(--font-secondary);
    @include ti-1;
    &--bg {
        &.btn--primary {
            background-color: var(--btn-primary);
            color: var(--btn-primary-contrast);
            @include hover {
                &:hover {
                    background-color: var(--btn-primary-hover);
                }
            }
            &:active {
                background-color: var(--btn-primary-hover);
            }
            &:disabled {
                pointer-events: none;
                background-color: var(--btn-disabled);
                color: var(--btn-disabled-contrast);
            }
        }
        &.btn--danger {
          background-color: var(--btn-danger);
          color: var(--btn-primary-contrast);
          @include hover {
            &:hover {
              background-color: var(--btn-danger-hover);
            }
          }
          &:active {
            background-color: var(--btn-primary-hover);
          }
          &:disabled {
            pointer-events: none;
            background-color: var(--btn-disabled);
            color: var(--btn-disabled-contrast);
          }
        }
        &.btn--secondary {
            background-color: var(--btn-secondary);
            color: var(--btn-secondary-contrast);
            @include hover {
                &:hover {
                    background-color: var(--btn-secondary-hover);
                }
            }
            &:active {
                background-color: var(--btn-secondary-hover);
            }
            &:disabled {
                pointer-events: none;
                background-color: var(--btn-disabled);
            }
        }
    }
    &--br {
        &.btn--primary {
            background-color: transparent;
            border: 1px solid var(--btn-primary);
            color: var(--btn-primary);
            @include hover {
                &:hover {
                    background-color: var(--btn-primary-hover);
                    color: var(--btn-primary-contrast);
                }
            }
            &:active {
                background-color: var(--btn-primary-hover);
                color: var(--btn-primary-contrast);
            }
            &:disabled {
                pointer-events: none;
                border-color: var(--btn-disabled);
                color: var(--btn-disabled);
            }
        }
        &.btn--secondary {
            background-color: transparent;
            border: 1px solid var(--btn-secondary);
            color: var(--btn-secondary);
            @include hover {
                &:hover {
                    background-color: var(--btn-secondary);
                    color: var(--btn-secondary-contrast);
                }
            }
            &:active {
                background-color: var(--btn-secondary);
                color: var(--btn-secondary-contrast);
            }
            &:disabled {
                pointer-events: none;
                border-color: var(--btn-disabled);
                color: var(--btn-disabled);
            }
        }
    }
}
.btn-text {
    display: flex;
    align-items: center;
    height: 32px;
    font-size: 16px;
    font-weight: 400;
    font-family: var(--font-secondary);
    background-color: transparent;
    white-space: nowrap;
    @include ti-1;
    &.btn--primary {
        color: var(--text-primary);
        @include hover {
            &:hover {
                color: var(--btn-primary) !important;
            }
        }
        &:active {
            color: var(--btn-primary) !important;
        }
        &:disabled {
            pointer-events: none;
            color: var(--btn-disabled);
        }
    }
    &.btn--secondary {
        color: var(--btn-primary);
        @include hover {
            &:hover {
                color: var(--text-primary) !important;
            }
        }
        &:active {
            color: var(--text-primary) !important;
        }
        &:disabled {
            pointer-events: none;
            color: var(--btn-disabled);
        }
    }
    &.btn--tertiary {
        color: var(--text-main);
        @include hover {
            &:hover {
                color: var(--btn-primary) !important;
            }
        }
        &:active {
            color: var(--btn-primary) !important;
        }
        &:disabled {
            background-color: transparent;
            pointer-events: none;
            color: var(--btn-disabled);
        }
    }
    &.btn--quaternary {
        color: var(--btn-primary);
        @include hover {
            &:hover {
                color: var(--btn-primary-contrast) !important;
            }
        }
        &:active {
            color: var(--btn-primary-contrast) !important;
        }
        &:disabled {
            pointer-events: none;
            color: var(--btn-disabled);
        }
    }
    svg {
        width: 24px;
        height: 24px;
        &:first-child {
            margin-right: 10px;
        }
        &:last-child {
            margin-left: 10px;
        }
    }
}
.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    width: 24px;
    height: 24px;
    @include ti-1;
    svg {
        width: 100%;
        height: 100%;
    }
    &.btn--primary {
        color: var(--btn-primary);
        @include hover {
            &:hover {
                color: var(--btn-primary-contrast) !important;
            }
        }
        &:active {
            color: var(--btn-primary-contrast) !important;
        }
        &:disabled {
            pointer-events: none;
            color: var(--btn-disabled);
        }
    }
}
.btn, .btn-text, .btn-icon, .pay-pal-btn {
    @include highlight;
}
.btn-cancel, .btn-accept {
    display: flex;
    background-color: transparent;
    width: 32px;
    height: 32px;
    color: var(--btn-cancel);
    @include ti-1;
    @include hover {
        &:hover {
            color: var(--btn-cancel-hover);
        }
    }
    &:active {
        color: var(--btn-cancel-hover);
    }
    svg {
        width: 100%;
        height: 100%;
    }
}
.btn-accept{
  @include hover {
    &:hover {
      color: var(--btn-primary-hover);
    }
  }
  &:active {
    color: var(--btn-primary-hover);
  }
}
.pay-pal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    max-width: 400px;
    width: 100%;
    border-radius: 30px;
    background-color: var(--paypal-button);
    @include ti-1;
    @include hover {
        &:hover {
            background-color: var(--paypal-button-hover);
        }
    }
    &:active {
        background-color: var(--paypal-button-hover);
    }
    &__svg {
        width: 100px;
        height: 26px;
        margin: -2px 6px 0 0;
    }
    &__text {
        font-size: 16px;
        color: var(--text-main);
        font-weight: 700;
    }
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    backdrop-filter: blur(20px);
    z-index: 9;
    background-color: var(--bg-header);
    .wrapper-large {
        display: flex;
        align-items: center;
        height: 100px;
        overflow: hidden;
        @include table {
            height: 80px;
        }
    }
    .overlay {
        height: 100vh;
    }
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 1280px) {
        position: fixed;
        top: 0;
        right: 0;
        max-width: 380px;
        width: 100%;
        height: 100vh;
        transition: transform .35s ease;
        transform: translateX(100%);
        flex-direction: column;
        background-color: var(--bg-secondary);
        padding: 15px 30px 60px;
        align-items: flex-end;
        justify-content: flex-start;
        overflow: hidden auto;
        z-index: 100;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        @include phone-small {
            max-width: unset;
        }
        &.active {
            transform: translateX(0%);
        }
        &.home {
            .wallet {
                opacity: 0;
                pointer-events: none;
            }
        }
        .nav {
            flex-direction: column;
            align-items: flex-end;
            margin: 64px 0 40px;
            &__link {
                &:not(:last-child) {
                    margin: 0 0 32px;
                }
            }
        }
        .btn, .wallet, .btn-text {
            margin-bottom: 40px;
        }
        .wallet {

        }
        .logo {
            display: none;
        }
        .social-network {
            display: flex !important;
            order: 6;
            margin-top: auto;
        }
    }
    @media screen and (min-width: 1281px) {
        .menu-btn {
            display: none;
        }
    }
    .social-network__link {
        color: var(--text-primary);
    }
    &:not(.home) {
        .btn {
            min-width: 200px;
        }
        @media screen and (max-width: 1400px) {
            .social-network {
                display: none;
            }
        }
    }
    .btn {
        font-size: 16px;
    }
}
.header-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
    @media screen and (min-width: 1281px) {
        display: none;
    }
}
.menu-btn {
    display: flex;
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin-left: 24px;
    color: var(--text-primary);
    background-color: transparent;
    @include ti-1;
    @include hover {
        &:hover {
            color: var(--color-primary);
        }
    }
    &:active {
        color: var(--color-primary);
    }
    svg {
        width: 100%;
        height: 100%;
    }
}
.logo {
    display: flex;
    width: 100%;
    max-width: 222px;
}
.social-network {
    display: flex;
    &__link {
        display: flex;
        width: 24px;
        height: 24px;
        @include ti-1;
        &:not(:last-child) {
            margin-right: 12px;
        }
        svg {
            width: 100%;
            height: 100%;
        }
        @include hover {
            &:hover {
                color: var(--color-primary) !important;
            }
        }
        &:active {
            color: var(--color-primary) !important;
        }
    }
}
.nav {
    display: flex;
    &__link  {
      cursor: pointer;
        font-family: var(--font-secondary);
        color: var(--text-primary);
        white-space: nowrap;
        line-height: 1.5;
        &:not(:last-child) {
            margin-right: 32px;
        }
        @include ti-1;
        @include hover {
            &:hover {
                color: var(--color-primary);
            }
        }
        &:active {
            color: var(--color-primary);
        }
    }
}
.wallet {
    display: flex;
    align-items: center;
    white-space: nowrap;
    background-color: transparent;
    img {
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 10px;
    }
    span {
        font-family: var(--font-secondary);
        font-weight: 500;
        font-size: 18px;
        color: var(--color-primary);
        padding-top: 2px;
        line-height: 1;
    }
}

footer {
    padding: 50px 0 32px;
    overflow: hidden;
    @include table {
        padding-bottom: 42px;
    }
    .wrapper-large {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 166px;
    }
    .nav {
        margin: 0;
        &__link {
            color: var(--text-main);
            @include table {
                &:not(:last-child) {
                    margin-right: 8px;
                }
            }
        }
    }
    .social-network {
        &__link {
            color: var(--text-main);
        }
    }
    .btn {
        min-width: unset;
        width: 100%;
        font-size: 16px;
    }
}
.footer {
    &__row {
        display: flex;
        justify-content: space-between;
        &:first-child {
            .footer__colum {
                justify-content: center;
            }
        }
        @media screen and (max-width: 1024px) and (min-width: 481px) {
            align-items: center;
        }
        @include table {
            flex-direction: column;
            &:first-child {
                margin-bottom: 50px;
                .footer__colum {
                    &:nth-child(1) {
                        margin-bottom: 50px;
                    }
                    &:nth-child(2) {
                        margin-bottom: 50px;
                        max-width: 346px;
                        .nav {
                            width: 100%;
                            justify-content: space-between;
                          gap: 20px;
                          flex-wrap: wrap;
                        }
                    }
                }
            }
            &:nth-child(2) {
                flex-direction: column-reverse;
                .footer__colum {
                    &:nth-child(3) {
                        margin-bottom: 50px;
                    }
                    &:nth-child(2) {
                        margin-bottom: 24px;
                    }
                }
            }
        }
        @include phone-small {
            &:first-child {
                .footer__colum {
                    &:nth-child(2), &:nth-child(3) {
                        max-width: unset;
                    }
                }
            }
        }
    }
    &__colum {
        display: flex;
        flex-direction: column;
        width: 100%;
        &:nth-child(3) {
            max-width: 346px;
        }
        @include table--min {
            &:nth-child(1) {
                max-width: 224px;
            }
            &:nth-child(2) {
                max-width: 570px;
                margin: 0 32px;
                min-width: 368px;
            }
        }
        @media screen and (max-width: 1024px) and (min-width: 481px) {
            text-align: center;
            &:not(:nth-child(3)) {
                align-items: center;
            }
        }
    }
    &__group {
        display: flex;
        align-items: center;
        align-self: center;
        .social-network {
            margin-right: auto;
        }
    }
    &__email, &__copyright {
        font-family: var(--font-tertiary);
        color: var(--text-secondary);
        font-size: 14px;
    }
    &__email {
        @include table--min {
            align-self: flex-start;
        }
        @include ti-1;
        @include hover {
            &:hover {
                color: var(--text-main);
            }
        }
        &:active {
            color: var(--text-main);
        }
    }
}
.tabs {
    display: flex;
    height: 60px;
    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: max(14px, min(1.6vw, 16px));
        padding: 0 12px;
        font-weight: 500;
        color: var(--color-primary);
        position: relative;
        text-align: center;
        //pointer-events: none;
        @include ti-1;
        @include highlight;
        @include hover {
        }
        &:active {
            color: var(--text-primary);
        }
        &.active {
            background-color: var(--bg-primary);
            color: var(--color-primary-contrast);
            cursor: default;
        }
    }
}
.select {
    display: flex;
    width: 100%;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background: url(../../icons/arrow-down--black.svg) center/contain no-repeat;
        pointer-events: none;
    }
    select {
        background-color: var(--bg-tertiary);
        padding: 0 60px 0 30px;
        height: 54px;
        border: unset;
        width: 100%;
        font-family: var(--font-primary);
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
    }
}
.post-card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px 4.2%;
    padding-top: 50px;
    .post-card-top, .post-card-middle, .post-card__btn-group, .post-card-price, .post-card-desc {
        max-width: 325px;
        width: 100%;
    }
    @include table {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 680px) {
        grid-template-columns: repeat(1, 1fr);
    }
}
.post-card {
  height: 100%;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-primary);
    box-shadow: 0px 10px 25px -25px rgba(27, 0, 46, 0.25);
    padding: 20px 24px;
    @media screen and (min-width: 681px) and (max-width: 1024px) {
        padding: 12px 9px 18px;
    }
    &__btn-group {
        display: flex;
        justify-content: center;
        &.large {
            .post-card-btn {
                svg {
                    width: 40px;
                    height: 40px;
                }
                &:not(:last-child) {
                    margin-right: 40px;
                }
            }
        }
        &.medium {
            .post-card-btn {
                pointer-events: none;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    }
    &--secondary {
        padding: 30px 32px 10px;
        @media screen and (min-width: 681px) and (max-width: 1024px) {
            padding: 30px 10px 10px;
        }
        .post-card-middle {
            padding: max(16px, min(2.1vw, 25px)) max(16px, min(2.1vw, 25px)) 10px;
            margin-bottom: 10px;
            &__group {
                width: calc(100% - 80px);
                padding-left: 8px;
                padding-right: 8px;
            }
            &__img {
                margin-bottom: 10px;
            }
            &__author {
                margin-bottom: 0;
            }
        }
        .post-card-top__icon {
            margin-left: auto;
        }
    }
  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 14px 0 0;
    .btn {
      min-width: 40px;
      min-height: 20px;
      width: 50px;
      height: 30px;

      &__text {
        font-size: 14px;
        line-height: 1;
      }

      &.selected {
        background-color: var(--color-primary);
        color: white;
      }
    }
  }
  &__text {
    color: var(--text-secondary);
    font-size: 14px;
    margin-right: 6px;
  }
}
.post-card-top {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    &__icon {
        width: 34px;
        height: 34px;
        object-fit: contain;
    }
    &__group {
        display: flex;
        flex-direction: column;
        padding: 0 8px;
        margin-right: auto;
        white-space: nowrap;
    }
    &__text {
        font-size: 12px;
        line-height: 18px;
        b {
            font-weight: 600;
        }
    }
    &__number {
        font-size: max(16px, min(2vw, 24px));
        font-weight: 600;
        line-height: 1;
        color: var(--color-secondary);
    }
}
.post-card-middle {
    display: flex;
    flex-direction: column;
    padding: 10px 24px 16px;
    border: 1px solid var(--text-main);
    margin-bottom: 24px;
    &__author {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
    }
    &__avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        object-fit: cover;
    }
    &__group {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 46px;
        width: calc(100% - 46px);
        padding-left: 8px;
    }
    &__name {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &__date {
        color: var(--text-secondary);
        font-size: 10px;
        line-height: 12px;
    }
    &__img {
        display: flex;
        position: relative;
        width: 100%;
        padding-bottom: 110%;
        margin-bottom: 12px;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          &.play-icon {
            width: 40px;
            height: 40px;

            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
    }
    &__text {
        color: var(--text-secondary);
        font-size: 10px;
        font-weight: 600;
        padding: 0 18px;
        line-height: 1.4;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 28px;
    }
}
.post-card-price {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 20px;
    background-color: var(--bg-secondary);
    color: var(--text-tertiary);
    &__text {
        font-family: var(--font-tertiary);
        font-size: 12px;
        padding-right: 10px;
        margin-right: auto;
    }
    &__number {
        font-size: max(16px, min(2vw, 24px));
        font-weight: 600;
    }
}
.post-card-desc {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    &__text, &__tags {
        font-size: 10px;
        line-height: 14px;
    }
    &__tags {
        margin-top: 10px;
        color: var(--text-secondary);
    }
}
.post-card-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    font-family: var(--font-tertiary);
    @include hover {
        &.success:hover {
            span {
                color: var(--color-success);
            }
        }
        &.warning:hover {
            span {
                color: var(--color-warning);
            }
        }
        &.danger:hover {
            span {
                color: var(--color-danger);
            }
        }
    }
    &.no-active {
        svg {
            fill: var(--bg-tertiary);
        }
    }
    svg {
        width: 30px;
        height: 30px;
    }
    span {
        font-size: 10px;
        color: var(--text-secondary);
        line-height: 14px;
        text-transform: uppercase;
        margin-top: 6px;
        @include ti-1;
    }
}
.graph-section {
    &:not(:last-child) {
        margin-bottom: 76px;
        @include table {
            margin-bottom: 50px;
        }
    }
    .wrapper-large {
        @include phone-small {
            padding: 0;
        }
    }
    h2 {
        font-size: max(36px, min(4.7vw, 48px));
        font-family: var(--font-secondary);
        font-weight: 700;
        color: var(--text-main);
        line-height: 1;
    }
    &__cont {
        display: flex;
        width: 100%;
        background-color: var(--bg-primary);
        .wrapper {
            padding-top: 52px;
            padding-bottom: 52px;
        }
    }
    &__two {
        display: flex;
        justify-content: space-between;
        @include table {
            flex-direction: column;
        }
        h2 {
            margin-bottom: 30px;
        }
        .graph-section__block {
            padding: 0;
            margin: unset;
            &:nth-child(1) {
                @include table--min {
                    max-width: max(324px,min(31.4vw,400px));
                    margin-right: 32px;
                }
                @include table {
                    max-width: 400px;
                    margin-bottom: 50px;
                }
            }
            &:nth-child(2) {
                max-width: 700px;
                @include table--min {
                    h2 {
                        text-align: right;
                    }
                }
            }
        }
    }
    &__block {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1260px;
        margin: 0 auto;
        @include phone-large--min {
            padding: 42px 32px;
        }
        @include phone-large {
            padding: 42px 29px;
        }
        @include phone-small {
            padding: 48px 29px;
        }
    }
    &__subtitle {
        color: var(--text-secondary);
        margin-top: 10px;
        margin-bottom: 42px;
        @include phone-small {
            margin: 20px 0;
        }
    }
    &__grid {
        display: flex;
        @include phone-large--min {
            margin-top: -60px;
        }
        @include table {
            flex-direction: column;
        }
        .graph-section__cont {
            @include table--min {
                &:nth-child(1) {
                    max-width: 830px;
                    margin-right: 20px;
                    .graph-section__block {
                        max-width: 648px;
                    }
                }
                &:nth-child(2) {
                    max-width: 588px;
                    .graph-section__block {
                        max-width: 460px;
                    }
                }
            }
            @include table {
                &:nth-child(1) {
                    margin-bottom: 50px;
                }
            }
        }
    }
}
.graph-stat {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    @include phone-l--phone-s {
        flex-wrap: wrap;
    }
    @include phone-small {
        flex-direction: column;
    }
    @media screen and (min-width: 481px) {
        align-items: flex-end;
    }
    &__box {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 481px) {
            &:last-child {
                text-align: right;
            }
        }
        @include phone-l--phone-s {
            width: 50%;
            &:nth-child(1), &:nth-child(2) {
                margin-bottom: 24px;
            }
            &:nth-child(2) {
                text-align: right;
            }
        }
        @include phone-small {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    &__number {
        font-size: max(36px, min(4.7vw, 48px));
        line-height: 1;
        color: var(--color-secondary);
        @include phone-small {
            font-size: 48px;
        }
    }
    &__title {
        font-size: 20px;
        color: var(--text-secondary);
    }
    &__location {
        display: flex;
        align-items: center;
        font-weight: 500;
        margin-bottom: 5px;
        font-family: var(--font-secondary);
        @media screen and (min-width: 481px) {
            justify-content: flex-end;
        }
        img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
        }
    }
}
.overlay-bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--overlay);
    backdrop-filter: blur(7.5px);
    overflow: hidden auto;
    @include phone-large--min {
        padding: 48px 32px;
    }
    @include phone-large {
        padding: 48px 24px;
    }
    @include phone-small {
        padding: 48px 0px;
    }
}
.form {
    &__box {
        display: flex;
        flex-direction: column;
        position: relative;
        &:not(:last-child) {
            margin-bottom: 26px;
        }
      &.single-box {
        margin-bottom: 26px;
        margin-right: 0;
      }
      &.small-box {
        width: 50%;
      }
        &.error, &.warning, &.success {
            .form__label {
                padding-right: 120px;
            }
            .form__valid {
                opacity: 1;
                pointer-events: auto;
            }
        }
        &.error {
            .form__label {
                color: var(--valid-error-shade);
            }
            .form__input, .form__textarea {
                border-color: var(--valid-error-shade);
                background-color: var(--valid-error-shade);
            }
            .form__valid {
                background-color: var(--valid-error);
                &:after {
                    border-top-color: var(--valid-error);
                    border-right-color: var(--valid-error);
                }
            }
        }
      &.success {
        .form__label {
          //color: var(--valid-error-shade);
        }
        .form__input, .form__textarea {
          border-color: var(--color-primary-hover);
          background-color: rgb(121, 218, 211);
        }
        .form__valid {
          background-color: var(--color-primary-hover);
          &:after {
            border-top-color: var(--color-primary-hover);
            border-right-color: var(--color-primary-hover);
          }
        }
      }
        &.warning {
            .form__label {
                color: var(--valid-warning-shade);
            }
            .form__input, .form__textarea {
                border-color: var(--valid-warning-shade);
                background-color: var(--valid-warning-shade);
            }
            .form__valid {
                background-color: var(--valid-warning);
                &:after {
                    border-top-color: var(--valid-warning);
                    border-right-color: var(--valid-warning);
                }
            }
        }
      .select__container {
        position: relative;
        .drop-down-arrow {
          position: absolute;
          top: 40%;
          right: 10px;
          width: 15px;
          height: 15px;
          pointer-events: none;
          background: url(../../icons/arrow-down--black.svg) center/contain no-repeat;
          z-index: 2;
        }
      }
    }
    &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--text-secondary);
        margin-bottom: 10px;
        .info {
            display: flex;
            width: 20px;
            min-width: 20px;
            height: 20px;
            padding: 2px;
            margin-left: 10px;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            .tooltip {
                min-width: 160px;
                &.right {
                    right: 4px;
                }
            }
        }
    }
    &__input, &__textarea, &__select {
        width: 100%;
        background-color: var(--bg-tertiary);
        font-size: 16px;
        border: 1px solid var(--bg-tertiary);
        @include ti-1;
        &:focus {
            border-color: var(--color-primary);
        }
        &::-webkit-input-placeholder { color: var(--text-secondary); }
        &:-ms-input-placeholder { color: var(--text-secondary); }
        &::-ms-input-placeholder { color: var(--text-secondary); }
        &::placeholder { color: var(--text-secondary); }
    }
    &__input, &__select, &__select_option {
        height: 55px;
        padding: 0 16px;
    }
    &__select {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      .select__value {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .select__drop_down {
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
      }
      .form__select_option {
        display: flex;
        align-items: center;
        background-color: var(--table-td);
        font-size: 16px;
        height: 45px;
        user-select: none;
      }
    }
    &__textarea {
        height: 150px;
        padding: 12px 16px;
        resize: none;
        &::-webkit-scrollbar {
            width: 4px;
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--color-primary);
        }
    }
    &__icon--left {
        .form__input {
            padding-left: 42px;
        }
        .icon-left {
            position: absolute;
            bottom: 20px;
            left: 15px;
            width: 14px;
            height: 14px;
            object-fit: contain;
        }
    }
    &__date {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 20px;
            width: 24px;
            height: 24px;
            background: url("../../icons/calendar.svg") center/contain no-repeat;
            transform: translateY(-50%);
            pointer-events: none;
        }
        .form__input {
            padding: 0 18px;
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
            font-size: 24px;
            opacity: 0;
        }
    }
    &__btn--right {
        .form__input {
            padding-right: 50px;
        }
        .btn-right {
            position: absolute;
            bottom: 14px;
            right: 14px;
            display: flex;
            width: 26px;
            height: 26px;
            color: var(--color-primary);
            cursor: pointer;
            @include ti-1;
            svg {
                width: 100%;
                height: 100%;
            }
            @include hover {
                &:hover {
                    color: var(--text-main);
                }
            }
            &:active {
                color: var(--text-main);
            }
        }
    }
    &__upload {
        max-width: 465px;
        img{
          width: auto;
          max-height: 100px;
          float: right;
        }
    }
    &__two-column{
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: space-between;

      .form__upload{
        min-width: 45%;
      }
    }
    &__valid {
        display: flex;
        align-items: center;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 6px;
        font-size: 12px;
        line-height: 1;
        color: var(--text-primary);
        opacity: 0;
        pointer-events: none;
        &:after {
            content: "";
            position: absolute;
            top: 100%;
            right: 0;
            border: 3px solid transparent;
        }
    }
}
.form-upload {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--bg-tertiary);
    border: 1px solid var(--bg-tertiary);
    height: 55px;
    padding: 0 64px 0 30px;
    position: relative;
    cursor: pointer;
    input {
        display: none;
    }
    &__text {
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    &__icon {
        position: absolute;
        top: 50%;
        right: 30px;
        width: 24px;
        height: 24px;
        object-fit: contain;
        transform: translateY(-50%);
    }
    &__info {
        color: var(--text-quaternary);
        font-size: 14px;
        padding-left: 30px;
        padding-top: 8px;
    }
}
.checked {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include highlight;
    input {
        display: none;
        &:checked ~ {
            .checked__circle:after {
                opacity: 1;
            }
        }
    }
    &.error {
      border: 1px solid red;
    }
    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        min-width: 16px;
        height: 16px;
        position: relative;
        border-radius: 50%;
        background-color: var(--bg-tertiary);
        &:after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--text-main);
            opacity: 0;
            @include ti-1;
        }
    }
    &__text {
        margin-left: 10px;
        font-size: 14px;
        color: var(--text-quaternary);
        font-family: var(--font-tertiary);
    }
    span {
        cursor: pointer;
    }
}
.platforms {
    &__box {
        display: flex;
        align-items: center;
        min-height: 55px;
        padding: 15px 28px;
        background-color: var(--table-td);
    }
    .fashionista-tab {
        &__btn {
            width: 24px;
            height: 24px;
            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }
}
.gender {
    margin-top: 20px;
    &__box {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        max-width: 316px;
        width: 100%;

    }
}

  .ngx-slider {
    .ngx-slider-selection {
      background: var(---color-primary) !important;
    }
    .ngx-slider-pointer {
      background: var(--color-primary) !important;
    }
  }


.upload-img {
    display: flex;
    position: relative;
    &.large {
        height: 260px;
        .upload-img__icon {
            width: 128px;
            height: 128px;
        }
    }
    &.medium {
        height: 145px;
        .upload-img__icon {
            width: 64px;
            height: 64px;
        }
    }
    &__group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        border: 2px solid var(--bg-tertiary);
        padding: 16px;
        cursor: pointer;
        @include ti-1;
        @include hover {
            &:hover {
                background-color: var(--bg-tertiary);
            }
        }
        &:active {
            background-color: var(--bg-tertiary);
        }
    }
    &__icon {
        object-fit: contain;
    }
    &__text {
        margin-top: 10px;
    }
    &__box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__close {
        display: flex;
        position: absolute;
        top: 8px;
        right: 8px;
        width: 36px;
        height: 36px;
        color: var(--text-main);
        cursor: pointer;
        @include ti-1;
        @include hover {
            &:hover {
                color: var(--color-primary);
            }
        }
        &:active {
            color: var(--color-primary);
        }
        svg {
            width: 100%;
            height: 100%;
        }
    }
    input {
        display: none;
    }
    p, span {
        cursor: pointer;
    }
}
.checked-circle {
    display: flex;
    align-items: center;
    @include highlight;
    cursor: pointer;
    align-self: flex-start;
    span {
        cursor: pointer;
    }
    input {
        display: none;
        &:checked ~ .checked-circle__circle:after {
            opacity: 1;
        }
    }
    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: var(--bg-tertiary);
        &:after {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: var(--bg-secondary);
            @include ti-1;
            opacity: 0;
        }
    }
    &__text {
        padding-left: 4px;
        font-size: 12px;
        color: var(--text-secondary);
    }
}
.toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    @include highlight;
    span {
        cursor: pointer;
    }
    input {
        display: none;
        &:checked {
            ~ .toggle__box {
                background-color: var(--toggle-shade);
                &:after {
                    transform: translateX(20px);
                }
            }
        }
    }
    &__box {
        display: flex;
        width: 50px;
        min-width: 50px;
        height: 30px;
        background-color: var(--toggle);
        border-radius: 20px;
        position: relative;
        transition: .2s ease-in-out;
        &:after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: var(--toggle-contrast);
            border: 0.5px solid rgba(0, 0, 0, 0.04);
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);
            transition: .2s ease-in-out;
        }
    }
    &__text {
        font-size: 20px;
        padding-left: 16px;
        color: var(--toggle-text);
    }
}
.table {
  @include table {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
    &-head {
        display: flex;
    }
    &-main {
        display: flex;
        flex-direction: column;
    }
    &__td, &__th {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 8px 20px;
        word-break: break-word;
        @include table--min {
            &:not(:last-child) {
                margin-right: 20px;
            }
            &:nth-child(1) {
              max-width: 80px;
              display: grid;
              place-items: center;
            }
            &:nth-child(2) {
                max-width: 345px;
            }
            &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                max-width: 224px;
            }
        }
        @include table {
            &:nth-child(odd) {
                background-color: var(--table-td);
            }
            &:nth-child(even) {
                background-color: transparent;
            }
          &:first-child {
            display: none;
          }

          position: static !important;
          transform: none !important;
          order: initial !important;

          display: flex;
          justify-content: center;
          max-width: 100% !important;

          span, a {
            font-size: 20px;
          }
        }
        @include phone-small {
            padding-left: 14px;
            padding-right: 14px;
        }
    }
    &__th {
        justify-content: center;
        background-color: var(--table-th);
        min-height: 38px;
        span {
            color: var(--text-secondary);
            text-transform: uppercase;
        }
    }
    &__td {
      &:last-child {
        width: auto;
        background-color: transparent;
      }
        min-height: 60px;
        position: relative;
        &.image-dialog{
          cursor: pointer;
        }
        @include phone-table {
            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 3;
            }
            &:nth-child(3) {
                order: 2;
            }
            &:nth-child(4) {
                order: 4;
            }
        }
    }
    &__tr {
        display: flex;
        @include table--min {
            margin-top: 12px;
            &:nth-child(odd) {
                .table__td {
                  &:last-child {
                    width: 40px;
                    min-width: 40px;
                    padding: 0;
                    background-color: transparent;
                  }
                    background-color: var(--table-td);
                }
            }
        }
        @include table {
          width: 70vw;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          box-shadow: 0 0 5px var(--bg-quaternary);
        }
    }
    &__img {
        width: 46px;
        min-width: 46px;
        height: 46px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 16px;
    }
    &__text {
        font-size: 14px;
      &.campaign-name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__link {
        color: var(--color-primary);
        font-size: 14px;
        text-decoration: underline;
        text-decoration-skip-ink: unset;
        text-decoration-color: transparent;
        @include ti-1;
        @include hover {
            &:hover {
                text-decoration-color: var(--color-primary);
            }
        }
        &:active {
            text-decoration-color: var(--color-primary);
        }
    }
    &__number {
        font-size: 32px;
        font-weight: 500;
        color: var(--color-secondary);
    }
}
.tooltip-wrap {
    position: relative;
    &:hover {
        .tooltip {
            opacity: 1;
            transform: translateY(calc(-100% + -6px));
        }
    }
}
.tooltip {
    position: absolute;
    top: 0;
    z-index: 8;
    display: flex;
    justify-content: center;
    padding: 3px 10px;
    min-width: 75px;
    max-width: 180px;
    font-size: 12px;
    background-color: var(--color-primary);
    color: var(--text-primary);
    opacity: 0;
    transform: translateY(-100%);
    pointer-events: none;
    line-height: 1;
    transition: .2s ease;
    &:after {
        content: "";
        position: absolute;
        top: 100%;
        border: 3px solid transparent;
        border-top-color: var(--color-primary);
    }
    &.left {
        left: 0;
        &:after {
            left: 0;
            border-left-color: var(--color-primary);
        }
    }
    &.right {
        right: 0;
        &:after {
            right: 0;
            border-right-color: var(--color-primary);
        }
    }
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    @include phone-large {
        max-width: 314px;
        width: 100%;
        justify-content: space-between;
    }
    &__arrow {
        display: flex;
        width: 24px;
        height: 24px;
        color: var(--text-main);
        background-color: transparent;
        &:first-child {
            margin-right: 7px;
        }
        &:last-child {
            margin-left: 7px;
        }
        @include ti-1;
        @include hover {
            &:hover {
                color: var(--color-primary);
            }
        }
        &:active {
            color: var(--color-primary);
        }
        &:disabled {
            color: var(--text-secondary);
            opacity: .5;
            pointer-events: none;
        }
        svg {
            width: 100%;
            height: 100%;
        }
    }
    &__item {
        color: var(--text-main);
        padding: 0 7px;
        cursor: pointer;
        @include ti-1;
        @include hover {
            &:hover {
                color: var(--color-primary);
            }
        }
        &:active {
            color: var(--color-primary);
        }
    }
}
.interest-tile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 145px;
    color: var(--text-main);
    border: 2px solid currentColor;
    padding: 16px;
    cursor: pointer;
    @include ti-1;
    &.active {
        color: var(--color-primary);
    }
    @include hover {
        &:hover {
            color: var(--color-primary);
        }
    }
    &:active {
        color: var(--color-primary);
    }
    &__text {
        color: currentColor;
        cursor: pointer;
    }
    svg {
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 10px;
    }
}
.list {
    &__item {
        font-size: 14px;
        padding-left: 22px;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 9px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: currentColor;
            transform: translateY(-50%);
        }
    }
}
.current-brand {
    display: flex;
    flex-direction: column;
    padding-bottom: 6px;
    border-bottom: 2px solid var(--current-brand-border);
    min-width: 148px;
    word-break: break-word;
    &__title {
        font-size: 12px;
        line-height: 1;
    }
    &__name {
        font-size: 24px;
        font-weight: 600;
        color: var(--color-secondary);
    }
}
.review-tags {
    display: flex;
    flex-direction: column;
    &__icon {
        width: 14px;
        min-width: 14px;
        height: 14px;
        margin-right: 12px;
    }
    &__box {
        display: flex;
        flex-wrap: wrap;
    }
    &__tag {
        display: flex;
        padding: 0 16px;
        border: 1px solid currentColor;
        border-radius: 24px;
        margin-right: 10px;
        margin-bottom: 10px;
        font-family: var(--font-secondary);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
    }
}
.review-mood {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px 20px;
    @include phone-small {
        grid-template-columns: repeat(1, 1fr);
    }
    &__img {
        width: 100%;
        height: 114px;
        object-fit: cover;
    }
}
.link {
    color: var(--color-primary);
    text-decoration: underline;
    text-decoration-color: transparent;
    @include ti-1;
    @include hover {
        &:hover {
            text-decoration-color: currentColor;
        }
    }
    &:active {
        text-decoration-color: currentColor;
    }
}

.progress-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8%;

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: grid;
    place-items: center;
    backdrop-filter: blur(5px);
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: var(--color-primary)!important;
}
.toast-bottom-right {
  bottom: 100px;
}
.ngneat-dialog-content {
  width: auto!important;
}
.post-icon-group {
  height: 30px;
}
.no-posts__text {
  text-align: center;
  padding: 40px 0;
}
.feedback-block {
  &__tags {
    margin-top: 20px;
  }
  video {
    width: 100%;
    max-height: 550px;
  }
}
.feedback-change-text {
  width: 100%;
  height: 150px;
  resize: none;
  margin: 20px 0;
  border-color: var(--campagin-primary-text-color);
  padding: 10px;
}
.typical-page {
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  h1 {
    margin: 32px 0;
    text-align: center;
    &.big {
      font-size: 140px;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    min-height: 460px;
    position: relative;
    padding: 28px 32px;
  }
  &__title, &__subtitle {
    font-family: 'KronaOne', sans-serif;
    text-align: center;
    color: white;
  }
  &__title {
    font-size: 48px;
    text-transform: uppercase;
  }
  &__subtitle {
    font-size: 30px;
    margin-top: 36px;
  }
  &__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    &.max-660 {
      max-width: 660px;
      width: 100%;
    }
  }
  .btn, &__group {
    z-index: 2;
  }
  .btn {
    margin-top: auto;
    cursor: pointer;
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0px 15px 25px -6px rgba(27, 0, 46, 0.5);
  }
}
.campaign-box__hover {
  transition: .1s linear;
  position: absolute;
  background: rgba(75, 213, 202, 0.5);
  backdrop-filter: blur(5px);
  color: var(--gray);
  height: 100%;
  width: 100%;
  padding: 20px;
  visibility: hidden;
  cursor: pointer;
  .campaign-box__group{
    padding-top: 10px;
    cursor: pointer;
  }

  //:hover > & {
  //  visibility: visible;
  //}
}

app-registration-dialog, .cdk-overlay-pane {
  overflow: auto;
}

div.hidden {
  display: none!important;
}
