//@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
//@import '~ngx-toastr/toastr';
//
//@import "variables";
//@import "variables-dashboard";
//@import "fonts";
//@import "base";
//@import "base-internal";
//@import "plugin/slick";
@import "plugin/swiper";
//@import "plugin/animate";
//@import "plugin/mediaelementplayer";
//
//@import "components/footer";
//@import "components/header";
//@import "components/header-internal";
//@import "components/form";
//@import "components/form-internal";
//@import "components/home-page";
//@import "components/influencer";
//@import "components/contact";
//@import "components/amplification";
@import "components/privacy-policy";
@import "components/insights";
//@import "components/modal";
//@import "components/la-piattaforma";
//@import "components/risultati-garantiti";
//@import "components/saas-white-label";
//@import "components/modal-internal";
//@import "components/campaigns";
//@import "components/campaign-overview";
@import "components/my-campaign-overview__feedback";
//@import "components/campaign-overview-additional";
//@import "components/my-profile";
//@import "components/new-campaign";
//
//@import "media";
//@import "media-dashboard";
//
//
//.ngneat-dialog-content {
//  width: auto!important;
//}
//
//.mat-tooltip {
//  background-color: var(--tertiary-text-color);
//  font-size: 11px;
//}

@import "components/modal-internal";
@import "assets/scss/style";

//√@import "primeng/resources/themes/";
@import "primeng/resources/primeng.css";

// From components/home-page
.swiper-container {
  width: 100%;
  height: auto;
  padding: 55px 0 80px;
}
.slider-community, .swiper-community {
  max-width: 1332px;
  width: 100%;
  padding: 0 166px;
  margin: 0 auto;
  position: relative;
  .swiper-slide-active {
    .slider-community__link {
      transform: scale(1.2);
    }
  }
  &__box__item{
    // display: flex;
    flex-wrap: wrap;
  }
  &__link {
    display: block;
    box-shadow: 0px 10px 25px rgba(27,0,46,0.25);
    border-radius: 15px;
    transition: .25s linear;
    overflow: hidden;
    img {
      object-fit: cover;
      height: auto;
    }
    video{
      width: 100%;
    }
  }
  .slick-track {
    padding: 55px 0 80px;
  }
  & .slick-prev, & .slick-next {
    &:active {
      color: var(--btn-primary-4-hover);
    }
  }
}
