.builder-hero {
    padding-top: max(70px, min(14.8vw, 150px));
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    h1 {
        margin-bottom: 50px;
    }
    .select {
        max-width: 240px;
        margin: 0 auto;
        select {
            height: 60px;
            background-color: var(--bg-primary);
            text-align: center;
        }
        @include phone-large--min {
            display: none;
        }
    }
    .tabs {
        &__btn {
            max-width: 240px;
            width: 100%;
        }
        @include phone-large {
            display: none;
        }
    }
}
.builder {
    &__cont {
        width: 100%;
        max-width: 1196px;
        margin: 0 auto;
        &:not(.step-1) {
            .builder__main {
                display: flex;
                @include table {
                    flex-direction: column;
                }
            }
        }
        &.step-1 {
            .builder__main {
                display: flex;
                flex-direction: column;
            }
        }
        &.step-2 {
            .builder__grid-two {
                @include phone-large {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
        &.step-3 {
            .form__textarea {
                height: 166px;
            }
            .builder__main {
                @include table {
                    flex-direction: column-reverse;
                }
            }
            .builder__col:nth-child(1) {
                @include table {
                    margin: 26px 0 0;
                }
                @include table--min {
                    min-width: 500px;
                }
            }
            .builder__grid-two {
                @include phone-small {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
        &.step-4 {
            .builder__grid-two {
                &.linked-only {
                    text-align: center;
                    line-height: 1.5;
                }
                @include phone-small {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
            .btn-group {
                @include phone-large--min {
                    margin-bottom: 12px;
                }
                @include phone-large {
                    margin-bottom: 20px;
                }
            }
        }
        &.step-4, &.step-6 {
            .btn-group {
                display: flex;
                width: 100%;
                max-width: 468px;
                .btn {
                    width: 50%;
                    min-width: unset;
                    padding: 0 12px;
                    line-height: 1.1;
                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                    &.active {
                        background-color: var(--btn-primary-contrast);
                        color: var(--text-primary);
                    }
                }
            }
        }
        &.step-6 {
            .builder__col {
                &:nth-child(1) {
                    max-width: 466px;
                    @include table--min {
                        min-width: 466px;
                    }
                }
                &:nth-child(2) {
                    max-width: 588px;
                    @include table--min {
                        margin-left: auto;
                    }
                }
            }
            .builder__bottom {
                .btn--bg {
                    max-width: unset;
                    @include phone-large--min {
                        margin-left: 24px;
                    }
                }
            }
            .checked {
                max-width: 312px;
            }
        }
    }
    &__head {
        display: flex;
        flex-direction: column;
        max-width: 954px;
        width: 100%;
        margin: 0 auto 48px;
    }
    &__bottom {
        margin-top: 50px;
        display: flex;
        @include phone-large {
            flex-direction: column;
            .btn {
                align-self: flex-start;
            }
        }
        .btn--br {
            @include phone-large {
                margin-top: 30px;
                order: 2;
            }
        }
        .btn--bg {
            max-width: 344px;
            min-width: unset;
            width: 100%;
            @include phone-large--min {
                margin-left: auto;
            }
            @include phone-large {
                order: 1;
            }
        }
    }
    &__grid-two {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        .form__box {
            margin-bottom: 0;
        }
    }
    &__subtitle {
        font-size: max(16px, min(3.2vw, 32px));
        color: var(--text-secondary);
        margin-top: 20px;
    }
    &__col {
        display: flex;
        flex-direction: column;
        width: 100%;
        &:nth-child(1) {
            max-width: 710px;
            @include table--min {
                margin-right: 20px;
                min-width: 580px;
            }
            @include table {
                margin-bottom: 26px;
            }
        }
        &:nth-child(2) {
            max-width: 466px;
        }
    }
    .wrapper-large {
        padding-top: 86px;
        padding-bottom: 48px;
        background-color: var(--bg-primary);
        @include phone-table {
            padding-top: 62px;
        }
        @include phone-large {
            padding-top: 50px;
        }
    }
    .h1 {
        color: var(--text-main);
        line-height: 1;
    }
}
.reward-box {
    display: flex;
    padding: 12px 120px 12px max(12px, min(9.6vw, 36px));
    min-height: 90px;
    background-color: var(--campaign-unselected-type);
    position: relative;
    cursor: pointer;
    @include ti-1;
    &:not(:last-child) {
        margin-bottom: 10px;
    }
    @include table {
        padding-right: 60px;
    }
    @include phone-large--min {
        align-items: center;
        justify-content: space-between;
    }
    @include phone-large {
        flex-direction: column;
        padding-top: 16px;
        padding-bottom: 18px;
    }
    @include phone-small {
        padding-right: 50px;
    }
    @media screen and (max-width: 350px) {
        padding-left: 16px;
    }

    &.active, &:hover {
        background-color: var(--campaign-selected-type);
        .reward-box__title {
            color: var(--text-primary);
        }
        .reward-box__info {
            color: var(--text-primary);
        }
    }
    &__box {
        display: flex;
        align-items: center;
        flex: 1;
    }
    &__icon {
        width: max(32px, min(17.1vw, 64px));
        min-width: max(32px, min(17.1vw, 64px));
        height: max(32px, min(17.1vw, 64px));
        margin-right: max(12px, min(5.5vw, 20px));
        object-fit: contain;
        &__post {
          width: 32px;
          min-width: 32px;
          height: 32px;
          margin-right: 0;
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-right: 20px;
    }
    &__title {
        font-size: 20px;
        color: var(--text-primary);
        line-height: 1.5;
        font-weight: 700;
        @include ti-1;
        &.color {
            color: var(--text-primary) !important;
        }
    }
    &__info {
        display: flex;
        align-items: center;
        color: var(--text-primary);
        position: relative;
        max-width: 180px;
        margin-top: 2px;
        @include ti-1;
        svg {
            width: 24px;
            min-width: 24px;
            height: 24px;
            margin-right: 10px;
        }
        p {
            font-family: var(--font-secondary);
            font-weight: 500;
            white-space: nowrap;
            line-height: 1;
            padding-bottom: 2px;
        }
    }
    &__input-box {
        position: relative;
        @include phone-large {
            margin-top: 24px;
            max-width: 230px;
        }
        .reward-box__img {
            right: -80px;
            @include table {
                right: -40px;
            }
        }
    }
    &__img {
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        margin-left: auto;
        object-fit: contain;
        @include table {
            right: 20px;
        }
        @include phone-small {
            right: 10px;
        }
    }
    .form__input {
        max-width: 224px;
    }
    span, p {
        cursor: pointer;
    }
}
.build-review {
    &__block {
        display: flex;
        flex-direction: column;
    }
    &__grid-two {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 26px;
        @include phone-small {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__block, &__grid-two {
        &:not(:last-child) {
            margin-bottom: 26px;
        }
    }
    &__box {
        display: flex;
        flex-direction: column;
    }
    &__brand {
        display: flex;
        flex-wrap: wrap;
    }
    &__title {
        color: var(--text-secondary);
        margin-bottom: 10px;
    }
    &__text {
        font-size: 14px;
        line-height: 1.143;
    }
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--text-tertiary);
        width: 150px;
        height: 96px;
        margin-right: 18px;
        img {
            object-fit: contain;
            max-height: 100%;
        }
    }
    &__hero-img {
        width: 100%;
        height: max(160px, min(50vw,240px));
        object-fit: cover;
    }
    .current-brand {
        align-self: flex-end;
        margin-top: 16px;
    }
}
.main-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  a{
    margin-bottom: 30px;
    cursor: pointer;
    color: var(--text-primary)
  }
}
